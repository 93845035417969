import React, { useEffect, useState } from "react";
import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
  TbCircleNumber4Filled,
} from "react-icons/tb";
import InputCircleSelect from "../../components/Input/InputCircleSelect";
import InputCheckboxSelect from "../../components/Input/InputCheckBoxSelect";
import Button from "../../components/Button/Button";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function CreateCrew() {
  const [selectedName, setSelectedName] = useState<string | number>("");
  const [selectedDesc, setSelectedDesc] = useState<string | number>("");
  const [selectedGender, setSelectedGender] = useState<string>(""); // 성별 값은 문자열로 유지
  const [inputCount, setInputCount] = useState(0);
  const [selectedSkills, setSelectedSkills] = useState<(string | number)[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const options = ["슛", "스피드", "드리블", "골키퍼", "피지컬"];

  const isButtonActive =
    selectedDesc !== "" &&
    selectedName !== "" &&
    selectedGender !== "" &&
    selectedSkills.length > 0;

  const handleDescChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDesc(e.target.value);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedName(e.target.value);
  };

  const onInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputCount(e.target.value.length);
  };

  const handleDescChangeAndCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDescChange(e);
    onInputHandler(e);
  };

  // 성별 선택 로직
  const handleGenderChange = (gender: string | number) => {
    setSelectedGender(gender as string); // 성별을 문자열로 설정
  };

  // 상태 변경 감지를 위한 useEffect
  useEffect(() => {
  }, [selectedGender]); // selectedGender가 변경될 때마다 콘솔에 출력

  const handleSkillsChange = (items: (string | number)[]) => {
    setSelectedSkills(items);
  };

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  const handleSubmit = async () => {
    if (!isButtonActive) return;
  
    setIsLoading(true);
    setError(null);
  
    try {
      const crewGender =
        selectedGender === "남성"
          ? "MALE"
          : selectedGender === "여성"
          ? "FEMALE"
          : "MIXED";
  
      // selectedSkills를 영어로 변환
      const crewSkills = selectedSkills.map((skill) => {
        switch (skill) {
          case "슛":
            return "SHOT";
          case "스피드":
            return "SPEED";
          case "드리블":
            return "DRIBBLE";
          case "골키퍼":
            return "GOALKEEPER";
          case "피지컬":
            return "PHYSICAL";
          default:
            return skill;
        }
      });
  
      const payload = {
        crewName: selectedName,
        crewBio: selectedDesc,
        crewType: crewGender,
        crewSkill: crewSkills, // 변환된 crewSkills 사용
      };
  
      const response = await api.post(`/crews`, payload, {
        headers: {
          Authorization: `Bearer ${cookies.accessToken}`,
        },
      });
  
      const result = response.data;
  
      const detailId = result.data.crewId;
      navigate(`/crew/detail/${detailId}`, {
        state: {
          crewName: selectedName,
          crewBio: selectedDesc,
          crewType: crewGender,
          crewSkill: crewSkills,
          isLeader: true,
        },
      });

    } catch (error: any) {
      if (error.response?.status === 409) {
        setError("이미 크루에 소속되어 있습니다!");
      } else {
        setError(error.response?.data?.message || "An error occurred");
      }
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleCloseError = () => {
    setError(null);
    navigate("/mypage"); // 에러 창을 닫을 때 마이페이지로 이동
  };

  return (
    <div className="relative min-h-screen bg-backgroundColor">
      <div className="ml-[16px] pt-[36px]">
        <div className="text-[20px] font-bold text-white/60 font-Pretendard_Bold">
          크루 생성
        </div>
        <div className="mt-[45px] text-[24px] font-Pretendard_Bold">
          <div>함께할 크루를</div>
          <div>생성해 주세요!</div>
        </div>
        <div className="mt-[48px]">
          <div className="text-[16px]">
            <div className="flex mb-[20px] items-center">
              <TbCircleNumber1Filled
                className="text-[24px] mr-2 font-Montserrat"
                color="#DAFF47"
              />
              <span className="font-Pretendard_Regular">
                크루 이름을 입력해 주세요.
              </span>
            </div>
            <input
              type="string"
              value={selectedName}
              placeholder="크루의 이름을 입력해 주세요."
              onChange={handleNameChange}
              className="bg-cardBgColor rounded-xl h-[48px] w-[328px] p-[16px] text-[16px] text-neutral-500 font-Pretendard_Regular placeholder:text-neutral-500 placeholder-opacity-40"
            />
            <div className="mt-[40px] mb-[20px]">
              <div className="flex mb-[16px] items-center">
                <TbCircleNumber2Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  크루의 한줄 소개를 입력해 주세요.
                </span>
                <span className=" text-[#606060]">({inputCount} / 20)</span>
              </div>
              <input
                type="string"
                value={selectedDesc}
                onChange={handleDescChangeAndCount}
                placeholder="크루의 한줄 소개를 입력해 주세요."
                maxLength={20}
                className="bg-cardBgColor rounded-xl h-[48px] w-[328px] p-[16px] text-[16px] text-neutral-500 font-Pretendard_Regular placeholder:text-neutral-500 placeholder-opacity-40"
              />
            </div>
            <div className="mt-[40px] mr-[10px]">
              <div className="flex mb-[20px] items-center">
                <TbCircleNumber3Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  크루원들의 성별을 선택해 주세요.
                </span>
              </div>
              <InputCircleSelect
                selectedItem={selectedGender}
                onItemChange={handleGenderChange}
                title="크루원 성별 선택"
                itemList={["남성", "여성", "남여혼성"]}
                placeholder={selectedGender || "크루원 성별 선택"}
                modalHeight="271px"
              />
            </div>
            <div className="mt-[40px] mr-[10px]">
              <div className="flex mb-[20px] items-center">
                <TbCircleNumber4Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  크루의 자신있는 경기 능력을 선택해 주세요
                </span>
              </div>
              <InputCheckboxSelect
                selectedItems={selectedSkills}
                onItemChange={handleSkillsChange}
                title="경기 능력 선택"
                itemList={options}
                placeholder="경기 능력 선택"
                modalHeight="336px"
              />
            </div>
          </div>
        </div>
      </div>

      {error && (
        <div className="fixed top-5 left-1/2 transform -translate-x-1/2 bg-red-500 text-white rounded-lg shadow-lg p-4 w-11/12 max-w-sm z-50">
          <div className="flex items-center justify-between">
            <span className="font-semibold text-lg">Error</span>
            <button
              className="text-white focus:outline-none"
              onClick={handleCloseError} // 에러 닫기 버튼 클릭 시 마이페이지로 이동
            >
              &times;
            </button>
          </div>
          <div className="mt-2 text-sm">{error}</div>
        </div>
      )}

      <div className="w-full px-5 bottom-0 pb-5 pt-[40px] bg-backgroundColor">
        <Button
          text="다음"
          active={isButtonActive}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}
