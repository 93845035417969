import { FaCamera } from "react-icons/fa";
import GoBack from "../../components/Navigation/GoBack";
import { useState, useEffect } from "react";
import InputCircleSelect from "../../components/Input/InputCircleSelect";
import defaultImg from "../../assets/default.png";
import InputCheckBoxSelect from "../../components/Input/InputCheckBoxSelect";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function ChangeCrewInfo() {
  const [crewName, setCrewName] = useState<string>(""); // 크루 이름 state
  const [crewBio, setCrewBio] = useState<string>(""); // 크루 한줄 소개 state
  const [selectedSex, setSelectedSex] = useState<string>(""); // 성별 선택 state
  const [selectedSkills, setSelectedSkills] = useState<(string | number)[]>([]);
  const options = ["슛", "드리블", "피지컬", "스피드", "골키퍼"];
  const { detailId } = useParams();
  const [cookies] = useCookies();
  const navigate = useNavigate();

  const skillMap: { [key: string]: string } = {
    SHOT: "슛",
    DRIBBLE: "드리블",
    PHYSICAL: "피지컬",
    SPEED: "스피드",
    GOALKEEPER: "골키퍼",
  };

  const reverseSkillMap: { [key: string]: string } = {
    슛: "SHOT",
    드리블: "DRIBBLE",
    피지컬: "PHYSICAL",
    스피드: "SPEED",
    골키퍼: "GOALKEEPER",
  };

  useEffect(() => {
    const fetchCrewData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/crews/${detailId}`,
          {
            headers: {
              Authorization: `Bearer ${cookies.accessToken}`,
            },
          }
        );

        if (response.data.isSuccess) {
          const { crewName, crewBio, crewType, crewSkill } = response.data.data;
          setCrewName(crewName);
          setCrewBio(crewBio);
          // crewType을 영어로 변환하여 상태를 설정
          const translatedSex =
            crewType === "MALE"
              ? "남성"
              : crewType === "FEMALE"
              ? "여성"
              : "남녀 혼성";
          setSelectedSex(translatedSex);
          const translatedSkills = crewSkill.map(
            (skill: string) => skillMap[skill] || skill
          );
          setSelectedSkills(translatedSkills);
        } else {
          console.error("Failed to fetch crew data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching crew data:", error);
      }
    };

    fetchCrewData();
  }, [detailId, cookies.accessToken]);

  // 상태가 변경될 때마다 업데이트

  const handleSkillsChange = (items: (string | number)[]) => {
    setSelectedSkills(items);
  };

  const handleSexChange = (sex: string | number) => {
    const crewType =
      sex === "남성" ? "MALE" : sex === "여성" ? "FEMALE" : "MIXED";
    setSelectedSex(crewType);
  };

  const handleCrewBioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCrewBio(e.target.value);
  };

  const handleCrewNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCrewName(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const translatedSkills = selectedSkills.map(
        (skill) => reverseSkillMap[skill as string] || skill
      );
      const crewType =
        selectedSex === "남성"
          ? "MALE"
          : selectedSex === "여성"
          ? "FEMALE"
          : "MIXED";

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/crews/${detailId}`,
        {
          crewName: crewName,
          crewBio: crewBio,
          crewType: crewType,
          crewSkill: translatedSkills,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          },
        }
      );

      if (response.data.isSuccess) {
        navigate(`/crew/detail/${detailId}`);
      } else {
        console.error("Failed to update crew info:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating crew info:", error);
    }
  };

  return (
    <div className="min-h-screen flex flex-col py-6 px-5">
      <GoBack />
      <div className="pageContainer px-5">
        <div className="flex flex-col items-center py-10">
          <div className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl">
            <img
              src={defaultImg}
              alt="Default"
              className="rounded-3xl w-[77.73px] h-[81.56px] object-cover"
            />
          </div>
        </div>

        <div className="pb-6 flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <span>크루 이름</span>
            <input
              type="text"
              className="rounded-lg bg-cardBgColor focus:outline-2 placeholder:text-neutral-500 focus:outline-pointColor px-3 h-[48px] text-neutral-500"
              placeholder={"크루 이름을 입력하세요"}
              value={crewName}
              onChange={handleCrewNameChange}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="font-Pretendard_Regular">
              크루의 한줄 소개를 입력해 주세요.
            </div>
            <input
              type="string"
              value={crewBio}
              onChange={handleCrewBioChange}
              placeholder="크루의 한줄 소개를 입력해 주세요."
              maxLength={20}
              className="rounded-lg bg-cardBgColor focus:outline-2 placeholder:text-neutral-500 focus:outline-pointColor px-3 h-[48px] text-neutral-500"
            />
          </div>

          <div className="flex flex-col gap-2">
            <div>크루원 성별</div>
            <InputCircleSelect
              selectedItem={selectedSex}
              onItemChange={handleSexChange}
              title="크루원 성별 선택"
              itemList={["남성", "여성", "남녀 혼성"]}
              placeholder="크루원 성별 선택"
              modalHeight="271px"
            />
          </div>

          <div className="flex flex-col gap-2">
            <span>크루의 자신있는 능력</span>
            <InputCheckBoxSelect
              selectedItems={selectedSkills}
              onItemChange={handleSkillsChange}
              title="크루의 자신있는 능력"
              itemList={options}
              placeholder="크루의 자신있는 능력"
              modalHeight="336px"
            />
          </div>
          <button
            onClick={handleSubmit}
            className="rounded-lg bg-pointColor text-black font-Pretendard_Bold py-2 px-4 mt-10"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  );
}
