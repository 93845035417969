import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import RED from "../../assets/ProfileImgs/ProfileImg1.png";
import YELLOW from "../../assets/ProfileImgs/ProfileImg2.png";
import BLUE from "../../assets/ProfileImgs/ProfileImg3.png";
import GREEN from "../../assets/ProfileImgs/ProfileImg4.png";

interface playListProps {
  crewName: string | null;
  nickName: string;
  playerId: number;
  playerRating: number;
  profileImage: string;
  stylePreference: string;
}
interface kickersProps {
  playerList: playListProps | null;
}

export default function Kickers() {
  const { matchId } = useParams();
  const [cookies] = useCookies();
  const [kickers, setKickers] = useState<kickersProps | null>(null);
  const [players, setPlayers] = useState<playListProps[] | null>(null);
  const navigate = useNavigate();
  const styleEnum = {
    ATTACK: "공격",
    DEFENSE: "수비",
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/matches/${matchId}/kickers`, {
        headers: { Authorization: `Bearer ${cookies.accessToken}` },
      })
      .then((res) => {
        let temp = res.data.data;
        if (temp.playerList.length > 0) {
          for (let i = 0; i < temp.playerList.length; i++) {
            const key: keyof typeof styleEnum =
              temp.playerList[i].stylePreference;
            temp.playerList[i]["stylePreference"] = styleEnum[key];
          }
          setKickers(temp);
          setPlayers(temp.playerList);
        }
      })
      .catch((e) => console.error(e));
  }, []);
  const selectProfileImage = (type: string | null) => {
    if (type === "RED") {
      return (
        <div
          className="bg-center bg-cover size-[50px]"
          style={{
            backgroundImage: `url(${RED})`,
          }}
        />
      );
    } else if (type === "YELLOW") {
      return (
        <div
          className="bg-center bg-cover size-[50px]"
          style={{
            backgroundImage: `url(${YELLOW})`,
          }}
        />
      );
    } else if (type === "BLUE") {
      return (
        <div
          className="bg-center bg-cover size-[50px]"
          style={{
            backgroundImage: `url(${BLUE})`,
          }}
        />
      );
    } else if (type === "GREEN") {
      return (
        <div
          className="bg-center bg-cover size-[50px]"
          style={{
            backgroundImage: `url(${GREEN})`,
          }}
        />
      );
    } else {
      return <div className="bg-[#434343] rounded-xl size-[50px]" />;
    }
  };
  return (
    <>
      <div className="w-full px-10">
        매칭에 참여한 키커들을
        <br /> 확인해보세요!
      </div>
      <>
        {players ? (
          players.length > 0 ? (
            <div className="overflow-scroll">
              <div className="w-[1400px] flex flex-wrap overflow-x-scroll justify-start gap-3 pl-10 mb-10 max-h-[300px]">
                {players!.map((kicker, index) => (
                  <div
                    key={index}
                    className="bg-cardBgColor px-2 py-1 rounded-xl size-[140px] flex flex-col justify-center"
                  >
                    <div className="flex items-center justify-between">
                      {selectProfileImage(kicker.profileImage)}
                      <div className="border-[1px] text-[10px] w-[50px] rounded-3xl text-center opacity-45 self-start">
                        {kicker.crewName ? "크루참가" : "개인참가"}
                      </div>
                    </div>

                    <div>{kicker.nickName}</div>
                    <div className="flex items-center gap-2">
                      <span className="text-[12px] opacity-45">
                        경기 스타일
                      </span>
                      <span className="opacity-65 text-[15px]">
                        {kicker.stylePreference}
                      </span>
                    </div>

                    <div className="flex items-center gap-1 text-pointColor">
                      <FaStar />
                      {(kicker.playerRating / 20).toFixed(1)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap overflow-x-scroll justify-start gap-3 pl-10 mb-10 max-h-[300px]">
              Loading...
            </div>
          )
        ) : (
          <div className="flex flex-wrap overflow-x-scroll justify-start gap-3 pl-10 mb-10 max-h-[300px] opacity-50">
            매칭에 참여한 키커가 없습니다.
          </div>
        )}
      </>
    </>
  );
}
