import { FaUser } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi2";
import { IoPencil } from "react-icons/io5";
import defaultImg from "../../assets/default.png";
import { Link, useNavigate } from "react-router-dom";
import PrevGameCard from "../../components/Mypage/PrevGameCard";
import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import RED from "../../assets/ProfileImgs/ProfileImg1.png";
import YELLOW from "../../assets/ProfileImgs/ProfileImg2.png";
import BLUE from "../../assets/ProfileImgs/ProfileImg3.png";
import GREEN from "../../assets/ProfileImgs/ProfileImg4.png";

export interface PastMatch {
  matchId: number;
  date: Date;
  opponent: string;
  score: string;
}

export interface userProps {
  nickname: string;
  sex: string;
  stylePreference: string;
  skill: string[];
  ranking: number;
  rating: number;
  socialKingCount: number;
  matchesParticipated: number;
  mannersKingCount: number;
  badMannerCount: number;
  foulKingCount: 0;
  skillKingCount: number;
  atmosphereMakerCount: number;
  roughPlayerCount: number;
  matchesWithRoughPlay: number;
  crewRanking: {
    crewId: number;
    crewName: string;
    rank: bigint;
    rating: number;
    winRate: number;
  };
  pastMatches: PastMatch[]; // 지난 경기 확인: 지난 경기 히스토리, 좌우로 스크롤해서 보거나 화살표로 지난 경기 확인하는 페이지로 이동
}

export interface prevMatchesProps {
  id: number;
  loseTeamMember: number;
  losingScore: number;
  losingTeam: string;
  matchDate: string;
  matchGround: string;
  winTeamMember: number;
  winningScore: number;
  winningTeam: string;
  winningCrew?: string;
  losingCrew?: string;
  winningCrewId?: number;
  losingCrewId?: number;
}

export default function MyPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState<userProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [skills, setSkills] = useState<(string | undefined)[]>([]);
  const [playerId, setPlayerId] = useState<number>();
  const [cookies, , removeCookie] = useCookies();
  const [prevMatches, setPrevMatches] = useState<prevMatchesProps[] | null>(
    null
  );
  const [crewName, setCrewName] = useState(false);
  const skillEnum = {
    SHOT: "슛",
    SPEED: "스피드",
    DRIBBLE: "드리블",
    PHYSICAL: "피지컬",
    STAMINA: "지구력",
    GOALKEEPER: "골키퍼",
  };

  useEffect(() => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/members/info`, {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          },
        })
        .then((res) => {
          let temp = res.data.data;
          if (temp["stylePreference"] === "ATTACK") {
            temp["stylePreference"] = "공격";
          } else {
            temp["stylePreference"] = "수비";
          }
          setPrevMatches(temp.pastMatches);
          setUser(temp);
          setIsLoading(false);
          setProfileImage(res.data.data.profileImage);
          let tempSkills = [];
          for (let i = 0; i < res.data.data.skill.length; i++) {
            const key: keyof typeof skillEnum = res.data.data.skill[i];
            tempSkills.push(skillEnum[key]);
          }
          setSkills(tempSkills);
          if (res.data.data.crewRanking) {
            if (res.data.data.crewRanking.crewName) {
              setCrewName(true);
            }
          }
          setPlayerId(temp.id);
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(e);
    }
  }, []);
  const selectProfileImage = (type: string | null) => {
    if (type === "RED") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${RED})`,
          }}
        />
      );
    } else if (type === "YELLOW") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${YELLOW})`,
          }}
        />
      );
    } else if (type === "BLUE") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${BLUE})`,
          }}
        />
      );
    } else if (type === "GREEN") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${GREEN})`,
          }}
        />
      );
    } else {
      return (
        <div className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl">
          <FaUser className="opacity-10 text-[40px]" />
        </div>
      );
    }
  };
  return (
    <div className="pageContainer overflow-x-hidden">
      {isLoading ? (
        <div className="h-[85vh] flex justify-center items-center">
          <span className="loading loading-spinner loading-lg text-pointColor"></span>
        </div>
      ) : (
        <>
          <div className="py-10 flex items-center justify-around">
            <div className="flex flex-col">
              {selectProfileImage(profileImage)}

              <div
                className="cursor-pointer bg-[#373737] size-8 flex justify-center items-center rounded-full relative bottom-5 left-[80%]"
                onClick={() => navigate("/mypage/modify")}
              >
                <IoPencil className="text-lg opacity-50" />
              </div>
            </div>

            <div className="flex flex-col items-start gap-2 mb-8">
              <div className="flex items-end gap-2">
                <div className="font-bold text-[24px]">
                  {user?.nickname ?? "user"}
                </div>
                <div className="text-[#8C8C8C]">
                  {user?.sex === "MALE" ? "남성" : "여성"}
                </div>
              </div>

              <div className="flex gap-1 text-[#8C8C8C] bg-[#212121] px-4 py-1 rounded-[8px]">
                <span>크루</span>
                <span>|</span>
                <span>
                  {crewName ? user!.crewRanking.crewName : "소속 크루 없음"}
                </span>
              </div>

              <div className="flex items-end gap-2">
                <div className="text-white opacity-70 text-[14px]">
                  {user?.stylePreference
                    ? `${user.stylePreference}키커`
                    : "선호포지션이 없어요."}
                </div>
                <div className="text-white opacity-50 max-w-[90px] text-[10px] flex flex-wrap items-center gap-1">
                  {skills.map((s, i) => (
                    <span key={i}>#{s}</span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 py-5">
            <div className="text-2xl text-pointColor font-Montserrat font-bold">
              MY KICK
            </div>
            <div className="bg-cardBgColor flex justify-between px-5 text-xl py-5 rounded-xl text-[16px]">
              <div className="text-[16px]">나의 랭킹</div>
              <div className="text-pointColor font-Pretendard_Bold text-[16px]">
                {user?.ranking ? `${user.ranking}위` : "정보가 없어요."}
              </div>
            </div>

            <div className="bg-cardBgColor flex justify-between px-5 text-xl py-5 rounded-xl text-[16px]">
              <div className="text-[16px]">나의 평점</div>
              <div className="font-Pretendard_Bold text-[16px]">
                {user?.rating ? `${user.rating}점` : "정보가 없어요."}
              </div>
            </div>

            <div className="bg-cardBgColor flex justify-between px-5 text-xl py-5 rounded-xl text-[16px]">
              <div className="text-[16px]">참여한 경기</div>
              <div className="font-Pretendard_Bold text-[16px]">
                {user?.pastMatches
                  ? `${user.pastMatches.length}경기`
                  : "경기 기록이 없어요."}
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="mb-5 text-lg font-bold">추천 항목</div>
            <div className="flex justify-between">
              <div className="bg-cardBgColor text-[14px] size-[104px] rounded-xl py-[16px] px-[12px] flex flex-col justify-between">
                <div className="text-sm">매너왕</div>
                <div className="opacity-70">{`${user?.mannersKingCount}경기`}</div>
              </div>

              <div className="bg-cardBgColor text-[14px] size-[104px] rounded-xl py-[16px] px-[12px] flex flex-col justify-between">
                <div className="text-sm">실력왕</div>
                <div className="opacity-70">{`${user?.skillKingCount}경기`}</div>
              </div>

              <div className="bg-cardBgColor text-[14px] size-[104px] rounded-xl py-[16px] px-[12px] flex flex-col justify-between">
                <div className="text-sm">분위기 메이커</div>
                <div className="opacity-70">{`${user?.socialKingCount}경기`}</div>
              </div>
            </div>
          </div>

          <div className="py-5">
            <div className="mb-5 text-lg font-bold">패널티 항목</div>
            <div className="flex flex-col gap-3">
              <div className="bg-cardBgColor flex justify-between px-5 text-xl py-5 rounded-xl">
                <div className="text-[16px]">거친 플레이어</div>
                <div className="text-warningColor font-Pretendard_Bold text-[16px]">
                  {`${user?.foulKingCount}경기`}
                </div>
              </div>

              <div className="bg-cardBgColor flex justify-between px-5 text-xl py-5 rounded-xl">
                <div className="text-[16px]">좋지 않은 매너</div>
                <div className="text-warningColor font-Pretendard_Bold text-[16px]">
                  {`${user?.badMannerCount}경기`}
                </div>
              </div>
            </div>
          </div>

          <div className="my-3">
            <div className="border-8 absolute left-0 w-screen border-[#161616]" />
          </div>

          <div
            className="py-5"
            onClick={() => {
              if (user?.crewRanking) {
                navigate(`/crew/detail/${user?.crewRanking.crewId}`);
              }
            }}
          >
            <div className="flex items-center justify-between mb-5">
              <div className="text-lg font-bold">나의 크루 랭킹</div>
              <HiChevronRight className="text-3xl" />
            </div>
            <div className="bg-cardBgColor flex items-center gap-5 rounded-xl py-5 px-5">
              <img src={defaultImg} alt="default Image" />
              <div className="w-full">
                {user?.crewRanking ? (
                  <>
                    <div className="text-lg flex justify-between font-bold mb-3">
                      <span>{user?.crewRanking.crewName}</span>
                      <span>{`랭킹 ${user?.crewRanking.rank}위`}</span>
                    </div>
                    <div className="flex justify-between opacity-70">
                      <span>크루 평점</span>
                      <span>{user?.crewRanking.rating}점</span>
                    </div>
                    <div className="flex justify-between opacity-70">
                      <span>크루 승률</span>
                      <span>{user?.crewRanking.winRate}%</span>
                    </div>
                  </>
                ) : (
                  <div>소속된 크루가 없어요.</div>
                )}
              </div>
            </div>
          </div>
          <div className="my-3">
            <div className="border-8 absolute left-0 w-screen border-[#161616]" />
          </div>

          <div className="py-5">
            <div
              className="flex items-center justify-between mb-5"
              onClick={() => {
                if (prevMatches) {
                  navigate("/mypage/prevMatches", { state: { id: playerId } });
                }
              }}
            >
              <div className="text-lg font-bold">
                지난 경기를 확인해 보세요.
              </div>
              <HiChevronRight className="text-3xl" />
            </div>
            <div className="flex items-center gap-3 overflow-y-scroll">
              <div className="flex gap-2">
                {prevMatches ? (
                  prevMatches.map((prop, ind) => (
                    <PrevGameCard {...prop} key={ind} />
                  ))
                ) : (
                  <div>이전 매치가 없습니다!</div>
                )}
              </div>
            </div>
          </div>

          <div className="my-3">
            <div className="border-8 absolute left-0 w-screen border-[#161616]" />
          </div>

          <div className="flex flex-col gap-3 py-5">
            <div className="text-xl opacity-50 font-Montserrat font-bold pb-3">
              WE KICK
            </div>
            <Link
              to={
                "https://wekick-in-hufs.notion.site/WE-KICK-b9e1846d8c7e47308c2477a12ec5546d?pvs=4"
              }
              className="bg-cardBgColor flex justify-between px-5 text-xl py-4 rounded-xl items-center active:scale-[0.97] transition"
            >
              <div className="text-[15px] opacity-50">공지사항</div>
              <HiChevronRight className="text-xl opacity-50" />
            </Link>

            <Link
              to={
                "https://wekick-in-hufs.notion.site/WE-KICK-793b51903d0447ab93ec6ccd71872d42?pvs=4"
              }
              className="bg-cardBgColor flex justify-between px-5 text-xl py-4 rounded-xl items-center active:scale-[0.97] transition"
            >
              <div className="text-[15px] opacity-50">WE KICK 소개</div>
              <HiChevronRight className="text-xl opacity-50" />
            </Link>

            <Link
              to={
                "https://wekick-in-hufs.notion.site/Terms-of-Use-0e73cf7fce6246c09ecfdfd74d408841?pvs=4"
              }
              className="bg-cardBgColor flex justify-between px-5 text-xl py-4 rounded-xl items-center active:scale-[0.97] transition"
            >
              <div className="text-[15px] opacity-50">WE KICK 약관</div>
              <HiChevronRight className="text-xl opacity-50" />
            </Link>

            <Link
              to={"https://forms.gle/eUG37FHXtzmwoD7y7"}
              className="bg-cardBgColor flex justify-between px-5 text-xl py-4 rounded-xl items-center active:scale-[0.97] transition"
            >
              <div className="text-[15px] opacity-50">WE KICK에 문의하기</div>
              <HiChevronRight className="text-xl opacity-50" />
            </Link>
          </div>

          <div className="py-6 text-[12px] opacity-30 flex items-center justify-center gap-3">
            <span
              onClick={() => {
                try {
                  removeCookie("accessToken", { path: "/" });
                  removeCookie("refreshToken");
                  alert("로그아웃 되었습니다.");
                  navigate("/login");
                } catch (e) {
                  alert("문제가 발생했습니다!");
                }
              }}
            >
              로그아웃
            </span>
            <span>|</span>
            <span
              onClick={() => {
                const ok = window.confirm(
                  "정말로 탈퇴하시겠습니까?\n 탈퇴하시면 이전 매치 등 관련한 정보가 모두 사라집니다."
                );
                if (ok) {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/members/withdrawal`,
                      {
                        headers: {
                          Authorization: `Bearer ${cookies.accessToken}`,
                        },
                      }
                    )
                    .then((res) => {
                      alert("회원 탈퇴에 성공했습니다. 다시 찾아주세요!");
                      removeCookie("accessToken");
                      removeCookie("refreshToken");
                      navigate("/");
                    })
                    .catch((e) => console.log(e));
                }
              }}
            >
              회원 탈퇴
            </span>
          </div>
        </>
      )}
    </div>
  );
}
