import { IoShareSocial } from "react-icons/io5";
import GoBack from "../../components/Navigation/GoBack";
import MatchInfo from "../../components/MatchDetail/MatchInfo";
import Button from "../../components/Button/Button";
import { useEffect, useState } from "react";
import CancelModal from "../../components/MatchDetail/modals/CancelModal";
import ApplyMatch from "../../components/MatchDetail/modals/ApplyMatch";
import ShareModal from "../../components/MatchDetail/modals/Sharemodal";
import CancelWarningModal from "../../components/MatchDetail/modals/CancelWarningModal";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Timer from "../../components/MatchDetail/Timer";
import matchDetailBg from "../../assets/matchDetailBG.png";
import dormField from "../../assets/dormfield.png";
import mohyeon_field from "../../assets/mohyeon_field.png";

export interface matchInfoProps {
  crewList: string[];
  isEquipped: boolean;
  matchGround: string;
  matchId: number;
  matchStatus: string;
  matchTime: string;
  matchType: string;
  playerCount: number;
  matchGroundAddress: string;
}

export default function MatchDetail() {
  const matchTypeEnum = {
    FEMALE: "여성경기",
    MALE: "남성경기",
    MIXED: "혼성경기",
  };
  const [isMatch, setIsMatch] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [timer, setTimer] = useState(false);
  const [day, setDay] = useState("0요일");
  const [cookies] = useCookies();
  const [matchDetail, setMatchDetail] = useState<matchInfoProps | null>(null);
  const { matchId } = useParams();
  const dayNames = [
    "일요일",
    "월요일",
    "화요일",
    "수요일",
    "목요일",
    "금요일",
    "토요일",
  ];
  const navigate = useNavigate();

  const [showBtn, setShowBtn] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/matches/${matchId}`)
      .then((res) => {
        let temp = res.data.data;
        const tempType: keyof typeof matchTypeEnum = res.data.data.matchType;
        temp["matchType"] = matchTypeEnum[`${tempType}`];
        const now = new Date();
        const matchTime = new Date(res.data.data.matchTime);
        const timeDifference = matchTime.getTime() - now.getTime();
        const dayDifference = matchTime.getDate() - now.getDate();
        if (
          dayDifference <= 0 &&
          Math.floor((timeDifference / (1000 * 60 * 60)) % 24) < 2
        ) {
          if (
            temp.matchStatus === "RECRUITING" ||
            temp.matchStatus === "IN_PROGRESS"
          ) {
            setTimer(true);
          }
        }

        setMatchDetail(temp);
        setDay(dayNames[matchTime.getDay()]);
        console.log(temp);

        if (cookies.accessToken) {
          setShowBtn(true);
        }
      })
      .catch((e) => console.log(e));

    axios
      .get(`${process.env.REACT_APP_API_URL}/matches/${matchId}/isApplied`, {
        headers: { Authorization: `Bearer ${cookies.accessToken}` },
      })
      .then((res) => setIsMatch(!res.data.data.isApplied))
      .catch((err) => setShowBtn(false));
  }, []);

  const getStatus = (status: string | undefined) => {
    if (status) {
      if (status === "CANCELLED") {
        return (
          <div className="w-full px-5 fixed bottom-0 pb-5">
            <Button active={false} text="매칭이 취소됐어요." />
          </div>
        );
      } else if (status === "FINISHED") {
        return (
          <div className="w-full px-5 fixed bottom-0 pb-5">
            <Button active={false} text="매칭이 끝났습니다." />
          </div>
        );
      } else if (status === "IN_PROGRESS") {
        return (
          <div className="w-full px-5 fixed bottom-0 pb-5">
            <Button active={false} text="경기중입니다." />
          </div>
        );
      } else if (status === "READY") {
        return (
          <div className="w-full px-5 fixed bottom-0 pb-5">
            <Button active={false} text="매칭이 완료됐어요." />
          </div>
        );
      } else {
        return (
          <>
            {isMatch ? (
              <div
                className="w-full px-5 fixed bottom-0 pb-5"
                onClick={() => {
                  setIsMatch((prev) => !prev);
                  setIsModal(true);
                }}
              >
                <Button active={isMatch} text="키커로 매칭 신청하기" />
              </div>
            ) : (
              <div
                className="w-full px-5 fixed bottom-0 pb-5"
                onClick={() => {
                  setIsMatch((prev) => !prev);
                  setIsModal(true);
                }}
              >
                <Button active={isMatch} text="매칭 신청 취소하기" />
              </div>
            )}
          </>
        );
      }
    } else {
      return <Button active={false} text="Loading.." />;
    }
  };

  return (
    <>
      {isModal ? (
        <>
          {isMatch ? (
            <>
              {timer ? (
                <CancelWarningModal
                  setIsMatch={setIsMatch}
                  setIsModal={setIsModal}
                />
              ) : (
                <CancelModal setIsMatch={setIsMatch} setIsModal={setIsModal} />
              )}
            </>
          ) : (
            <ApplyMatch setIsModal={setIsModal} setIsMatch={setIsMatch} />
          )}
        </>
      ) : (
        <>{isShare ? <ShareModal setIsShare={setIsShare} /> : null}</>
      )}

      <div className="pageContainer min-h-screen relative z-40">
        <GoBack />
        <div
          className="w-full h-[200px] bg-cover bg-center mt-3"
          style={{
            backgroundImage: `${
              matchDetail?.matchGround === "모현읍 다목적구장"
                ? `url("${mohyeon_field}")`
                : matchDetail?.matchGround === "기숙사 풋살장"
                ? `url("${dormField}")`
                : `url("${matchDetailBg}")`
            }`,
          }}
        >
          {timer ? <Timer dataString={matchDetail?.matchTime} /> : null}
        </div>
        <div className="px-5 py-5">
          <div className="opacity-80">
            <div className="text-xl text-bold">{matchDetail?.matchGround}</div>
            <div className="opacity-60 text-sm">
              {matchDetail?.matchGroundAddress}
            </div>
          </div>

          <div className="pt-3 flex justify-between items-center">
            <span>
              <div className="text-[20px] font-Pretendard_Bold">
                {dayjs(matchDetail?.matchTime).format("YY.MM.DD ")} {day}
              </div>
              <div className="text-[32px] font-Montserrat font-bold">
                {dayjs(matchDetail?.matchTime).format("HH:mm")}
              </div>
            </span>
            <div
              className="bg-cardBgColor text-xl active:scale-75 cursor-pointer transition text-pointColor size-[48px] flex justify-center items-center rounded-full"
              onClick={() => setIsShare((prev) => !prev)}
            >
              <IoShareSocial />
            </div>
          </div>
        </div>

        <div className="border-4 w-full h-0 border-cardBgColor" />
        <MatchInfo matchDetail={matchDetail} isMatch={isMatch} />

        {showBtn ? (
          getStatus(matchDetail?.matchStatus)
        ) : (
          <div
            className="w-full px-5 fixed bottom-0 pb-5"
            onClick={() => navigate("/login")}
          >
            <Button active={true} text="로그인이 필요합니다." />
          </div>
        )}
      </div>
    </>
  );
}
