// ProtectedRoute.tsx
import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    const [cookies] = useCookies(['accessToken']);
    if (!cookies.accessToken) {
        alert("로그인이 필요한 기능입니다!")
        return <Navigate to="/login" />;
    }


    // 인증되었으면 자식 컴포넌트 렌더링
    return children;
};

export default ProtectedRoute;