import { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
} from "react-icons/tb";
import InputCircleSelect from "../../components/Input/InputCircleSelect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";

export default function GradeIndividual() {
  const { matchId } = useParams<{ matchId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location; // 이전 페이지에서 전달된 state
  const numericMatchId = parseInt(matchId as string, 10);

  const [cookies] = useCookies();

  const [selectedManner, setSelectedManner] = useState<number | string>("");
  const [selectedMVP, setSelectedMVP] = useState<number | string>("");
  const [selectedMood, setSelectedMood] = useState<number | string>("");
  const [selectedFoul, setSelectedFoul] = useState<number | string>("");
  const [selectedBadManner, setSelectedBadManner] = useState<number | string>(
    ""
  );
  const [selectedNoShow, setSelectedNoShow] = useState<number | string | null>(
    null
  );

  const [kickerOptions, setKickerOptions] = useState<
    { memberId: number; nickName: string }[]
  >([]);

  const [noShowOptions, setNoOptions] = useState<
    { memberId: number | null; nickName: string }[]
  >([]);

  useEffect(() => {
    if (state && state.matchData) {
      const participants: { memberId: number; nickName: string }[] = [];
      participants.push({ memberId: 0, nickName: "없음" });
      state.matchData.teamList.teamList.forEach((team: any) => {
        team.memberList.forEach((member: any) => {
          participants.push({
            memberId: member.memberId,
            nickName: member.nickName,
          });
        });
      });

      setKickerOptions(participants);
    }
  }, [state]);

  useEffect(() => {
    if (state && state.matchData) {
      const noShowParticipants: {
        memberId: number | null;
        nickName: string;
      }[] = [];
      noShowParticipants.push({ memberId: null, nickName: "없음" });
      state.matchData.teamList.teamList.forEach((team: any) => {
        team.memberList.forEach((member: any) => {
          noShowParticipants.push({
            memberId: member.memberId,
            nickName: member.nickName,
          });
        });
      });

      setNoOptions(noShowParticipants);
    }
  }, [state]);

  const isButtonActive =
    selectedManner !== "" &&
    selectedMVP !== "" &&
    selectedMood !== "" &&
    selectedFoul !== "" &&
    selectedBadManner !== "" &&
    selectedNoShow !== "";

  const handleSubmit = async () => {
    if (!isButtonActive) return;

    const payload = {
      userId: state.userId,
      matchId: state.matchId,
      winningTeam: state.winningTeam,
      myTeam: state.myTeam,
      score: state.selectedScore,
      mannerKing: selectedManner,
      skillKing: selectedMVP,
      socialKing: selectedMood,
      foulKing: selectedFoul,
      badManner: selectedBadManner,
      noShow: selectedNoShow,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/match-results`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          },
        }
      );
      if (state.teamFormationType === "CREW_INDIVIDUALS") {
        navigate(`/grade/crew/${numericMatchId}`);
      }

      // 성공적으로 제출되면 홈 화면으로 이동
      else {
        navigate("/home");
      }
    } catch (error) {
      console.error("평가 제출 중 오류 발생:", error);
      alert("평가 제출 중 오류가 발생했습니다.");
    }
  };

  const handleOptionChange = (
    value: string | number,
    setter: React.Dispatch<React.SetStateAction<number | string>>
  ) => {
    const selectedMember = kickerOptions.find(
      (option) => option.nickName === value
    );
    if (selectedMember) {
      setter(selectedMember.memberId); // memberId를 상태로 설정
    }
  };

  const handleNoShowOptionChange = (
    value: string | number,
    setter: React.Dispatch<React.SetStateAction<number | string | null>>,
    options: { memberId: number | null; nickName: string }[]
  ) => {
    const selectedMember = options.find((option) => option.nickName === value);

    // If "없음" is selected, set the value to null, otherwise use memberId
    if (selectedMember?.nickName === "없음") {
      setter(null); // This will allow null for 노쇼자
    } else if (selectedMember) {
      setter(selectedMember.memberId);
    }
  };

  const getSelectedNickName = (id: number | string) => {
    const selectedMember = kickerOptions.find(
      (option) => option.memberId === id
    );
    return selectedMember ? selectedMember.nickName : ""; // id에 맞는 nickName 반환
  };

  return (
    <div className="relative min-h-screen bg-backgroundColor">
      <div className="ml-[16px] pt-[36px]">
        <div className="text-[20px] font-bold text-white/60 font-Pretendard_Bold">
          개인 평가
        </div>
        <div className="mt-[45px] text-[24px] font-Pretendard_Bold">
          <div>함께 경기한 키커들 중</div>
          <div>KICKING을 뽑아주세요!</div>
        </div>
        <div className="mt-[48px] pr-[10px]">
          <div className="text-[16px] mb-[108px]">
            <div className="font-Pretendard_Bold mb-[20px]">키커 추천 항목</div>
            <div className="flex mb-[20px] items-center">
              <TbCircleNumber1Filled
                className="text-[24px] mr-2 font-Montserrat"
                color="#DAFF47"
              />
              <span className="font-Pretendard_Regular">
                오늘 매칭의 <span className="text-pointColor">매너왕</span>을
                뽑아주세요.
              </span>
            </div>
            <InputCircleSelect
              selectedItem={getSelectedNickName(selectedManner)}
              onItemChange={(value) =>
                handleOptionChange(value, setSelectedManner)
              }
              title="오늘의 매너왕"
              itemList={kickerOptions.map((option) => option.nickName)}
              placeholder={
                kickerOptions.find(
                  (option) => option.memberId === selectedManner
                )?.nickName || "키커 선택"
              } // undefined 처리
              modalHeight="400px"
            />
            <div className="mt-[40px] ">
              <div className="flex mb-[20px] items-center ">
                <TbCircleNumber2Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  오늘 매칭의 <span className="text-pointColor">실력왕</span>을
                  뽑아주세요.
                </span>
              </div>
              <InputCircleSelect
                selectedItem={getSelectedNickName(selectedMVP)}
                onItemChange={(value) =>
                  handleOptionChange(value, setSelectedMVP)
                }
                title="오늘의 실력왕"
                itemList={kickerOptions.map((option) => option.nickName)}
                placeholder={
                  kickerOptions.find(
                    (option) => option.memberId === selectedMVP
                  )?.nickName || "키커 선택"
                } // undefined 처리
                modalHeight="400px"
              />
            </div>
            <div className="mt-[40px] mb-[60px]">
              <div className="flex mb-[20px] items-center ">
                <TbCircleNumber3Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  오늘 매칭의{" "}
                  <span className="text-pointColor">분위기 메이커</span>를
                  뽑아주세요.
                </span>
              </div>
              <InputCircleSelect
                selectedItem={getSelectedNickName(selectedMood)}
                onItemChange={(value) =>
                  handleOptionChange(value, setSelectedMood)
                }
                title="오늘의 분위기 메이커"
                itemList={kickerOptions.map((option) => option.nickName)}
                placeholder={
                  kickerOptions.find(
                    (option) => option.memberId === selectedMood
                  )?.nickName || "키커 선택"
                } // undefined 처리
                modalHeight="400px"
              />
            </div>
          </div>
        </div>

        {/* 키커 패널티 항목 추가 */}
        <div className="pr-[10px]">
          <div className="text-[16px] mb-[93px]">
            <div className="font-Pretendard_Bold mb-[20px] mt-[40px]">
              키커 패널티 항목
            </div>
            <div className="flex mb-[20px] items-center">
              <TbCircleNumber1Filled
                className="text-[24px] mr-2 font-Montserrat"
                color="#DAFF47"
              />
              <span className="font-Pretendard_Regular">
                경기에서 거친 플레이의{" "}
                <span className="text-pointColor">반칙왕</span>이 있었나요?
              </span>
            </div>
            <InputCircleSelect
              selectedItem={getSelectedNickName(selectedFoul)}
              onItemChange={(value) =>
                handleOptionChange(value, setSelectedFoul)
              }
              title="오늘의 반칙왕"
              itemList={kickerOptions.map((option) => option.nickName)}
              placeholder={
                kickerOptions.find((option) => option.memberId === selectedFoul)
                  ?.nickName || "키커 선택"
              } // undefined 처리
              modalHeight="400px"
            />
            <div className="mt-[40px] mb-[60px]">
              <div className="flex mb-[16px] items-center ">
                <TbCircleNumber2Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  오늘 <span className="text-pointColor">좋지 않은 매너</span>의
                  키커가 있었나요?
                </span>
              </div>
              <InputCircleSelect
                selectedItem={getSelectedNickName(selectedBadManner)}
                onItemChange={(value) =>
                  handleOptionChange(value, setSelectedBadManner)
                }
                title="오늘의 비매너왕"
                itemList={kickerOptions.map((option) => option.nickName)}
                placeholder={
                  kickerOptions.find(
                    (option) => option.memberId === selectedBadManner
                  )?.nickName || "키커 선택"
                } // undefined 처리
                modalHeight="400px"
              />
            </div>
            <div className="mt-[40px] mb-[60px]">
              <div className="flex mb-[20px] items-center ">
                <TbCircleNumber3Filled
                  className="text-[24px] mr-2 font-Montserrat"
                  color="#DAFF47"
                />
                <span className="font-Pretendard_Regular">
                  오늘 경기에 불참한{" "}
                  <span className="text-pointColor">노쇼</span> 키커가 있었나요?
                </span>
              </div>
              <InputCircleSelect
                selectedItem={
                  selectedNoShow === null
                    ? ""
                    : getSelectedNickName(selectedNoShow)
                }
                onItemChange={(value) =>
                  handleNoShowOptionChange(
                    value,
                    setSelectedNoShow,
                    noShowOptions
                  )
                }
                title="노쇼자"
                itemList={noShowOptions.map((option) => option.nickName)}
                placeholder={
                  noShowOptions.find(
                    (option) => option.memberId === selectedNoShow
                  )?.nickName || "키커 선택"
                }
                modalHeight="400px"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-5 bottom-0 pb-5 bg-backgroundColor">
        <Button text="완료" active={isButtonActive} onClick={handleSubmit} />
      </div>
    </div>
  );
}
