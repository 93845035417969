import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import GoBack from "../../components/Navigation/GoBack";
import { HiChevronRight } from "react-icons/hi2";
import { IoPencil } from "react-icons/io5";
import { MdArrowOutward } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import PrevGameCard from "../../components/Mypage/PrevGameCard";
import PrevMemCard from "../../components/Crew/PrevMemCard";
import LeaveModal from "../../components/Crew/LeaveCrewModal";
import axios from "axios";
import { useCookies } from "react-cookie";
import defaultImg from "../../assets/default.png";
import ProfileImg1 from "../../assets/ProfileImgs/ProfileImg1.png";
import ProfileImg2 from "../../assets/ProfileImgs/ProfileImg2.png";
import ProfileImg3 from "../../assets/ProfileImgs/ProfileImg3.png";
import ProfileImg4 from "../../assets/ProfileImgs/ProfileImg4.png";

interface Member {
  memberId: number;
  profileImage: string;
  nickname: string;
  sex: string;
  stylePreference: string;
  rating: number;
}

interface Leader {
  memberId: number;
  nickname: string;
  profileImage: string | null;
  rating: number;
  sex: string;
  stylePreference: string;
}

interface CrewData {
  crewId: number;
  crewName: string;
  crewBio: string;
  crewType: string;
  crewSkill: string[];
  currentMemberId: number;
  crewLeader: Leader;
  matchCount: number;
  winRate: number;
  rank: number | null;
  memberList: Member[];
  currentMemberNickName: string;
}

export interface prevMatchesProps {
  id: number;
  matchDate: string;
  matchGround: string;
  winningTeam: string;
  losingTeam: string;
  winningScore: number;
  losingScore: number;
  winTeamMember: number;
  loseTeamMember: number;
  winningCrew: string;
  losingCrew: string;
}

// 이미지 맵핑 객체
const profileImageMap: { [key: string]: string } = {
  RED: ProfileImg1,
  YELLOW: ProfileImg2,
  BLUE: ProfileImg3,
  GREEN: ProfileImg4,
};

const stylePreferenceMap: { [key: string]: string } = {
  ATTACK: "공격",
  DEFENSE: "수비",
  // 다른 스타일이 있다면 추가하세요.
};

export default function CrewDetail() {
  const navigate = useNavigate();
  const { detailId } = useParams();
  const [cookies] = useCookies();
  const [crewData, setCrewData] = useState<CrewData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prevMatches, setPrevMatches] = useState<prevMatchesProps[]>([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchCrewData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/crews/${detailId}`,
          {
            headers: {
              Authorization: `Bearer ${cookies.accessToken}`,
            },
          }
        );
        setCrewData(response.data.data);
      } catch (error) {
        console.error("크루 데이터를 불러오는 중 오류 발생:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCrewData();
  }, [detailId, cookies.accessToken]);

  useEffect(() => {
    const fetchPrevMatches = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/record/crew/${detailId}?page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${cookies.accessToken}`,
            },
          }
        );
        if (response.data.isSuccess) {
          const matchData = response.data.data.content;
          setPrevMatches(matchData);
          setTotalPages(response.data.data.totalPages);
        } else {
          alert("지난 경기를 불러오지 못했습니다.");
        }
      } catch (error) {
        console.error("지난 경기 데이터를 불러오는 중 오류 발생:", error);
      }
    };

    fetchPrevMatches();
  }, [detailId, currentPage, cookies.accessToken]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleInviteClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/crews/${detailId}/recruit`,
        {},
        {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          },
        }
      );

      if (response.data.isSuccess) {
        const inviteUrl = response.data.data.recruitUrl;

        if (navigator.share) {
          try {
            await navigator.share({
              title: "크루 초대 링크",
              text: `크루리더가 크루원으로 초대했어요!👥 링크를 통해 크루원들과 함께 뛰어 봐요! \n${inviteUrl}\n [한국외대 재학생 풋살 매칭 WEKICK ⚽🥅 ]`,
            });
            alert("링크를 공유했습니다!");
          } catch (error) {
            alert("공유에 실패했습니다.");
          }
        } else {
          try {
            await navigator.clipboard.writeText(inviteUrl);
            alert("링크가 클립보드에 복사되었습니다.");
          } catch (error) {
            alert("클립보드에 복사하는데 실패했습니다.");
          }
        }
      } else {
        alert(`초대 링크 생성 실패: ${response.data.message}`);
      }
    } catch (error) {
      console.error("초대 링크 생성 중 오류 발생:", error);
      alert("초대 링크 생성 중 오류가 발생했습니다.");
    }
  };

  const handleLeaveCrew = async () => {
    if (!crewData) return;

    try {
      const otherMembers = crewData.memberList.filter(
        (member) => member.memberId !== crewData.crewLeader.memberId
      );

      if (isLeader && otherMembers.length > 0) {
        alert("크루 해체를 위해서는 다른 크루원이 없어야 합니다.");
        return;
      }

      const url = isLeader
        ? `${process.env.REACT_APP_API_URL}/crews/${detailId}`
        : `${process.env.REACT_APP_API_URL}/crews/${detailId}/withdraw`;

      const response = await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${cookies.accessToken}`,
        },
        data: isLeader ? {} : { memberId: crewData.crewLeader.memberId },
      });

      if (response.data.isSuccess) {
        if (isLeader) {
          alert("크루가 해체되었습니다.");
        } else {
          alert("크루에서 탈퇴되었습니다.");
        }
        navigate("/home");
      } else {
        alert(`작업 실패: ${response.data.message}`);
      }
    } catch (error) {
      console.error("작업 중 오류 발생:", error);
      alert("작업 중 오류가 발생했습니다.");
    }
  };

  if (isLoading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <span className="loading loading-spinner loading-lg text-pointColor"></span>
      </div>
    );
  }

  if (!crewData) {
    return <div>데이터를 불러오는 중 오류가 발생했습니다.</div>;
  }

  const isLeader = crewData
    ? crewData.currentMemberNickName === crewData.crewLeader.nickname
    : false;

  const isMember = crewData
    ? crewData.memberList.some(
        (member) => member.memberId === crewData.currentMemberId // currentMemberId가 없는 경우 -1로 대체
      )
    : false;


  const crewGender =
    crewData.crewType === "MIXED"
      ? "남녀혼성 크루"
      : crewData.crewType === "MALE"
      ? "남성 크루"
      : crewData.crewType === "FEMALE"
      ? "여성 크루"
      : "크루 성별 없음";

  return (
    <div className="min-h-screen flex flex-col py-6">
      <GoBack />
      <div className="pageContainer overflow-x-hidden">
        <div className="py-10 flex items-center flex-col">
          <div className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl">
            <img
              src={defaultImg}
              alt="Default"
              className="rounded-3xl w-[77.73px] h-[81.56px] object-cover"
            />
          </div>

          {isLeader && (
            <div
              className="cursor-pointer bg-[#373737] size-8 flex justify-center items-center rounded-full relative bottom-5 left-14"
              onClick={() => navigate(`/crew/modify/${detailId}`)}
            >
              <IoPencil className="text-lg opacity-50" />
            </div>
          )}

          <div className="flex flex-col items-center gap-2 mt-4">
            <div className="text-2xl font-Pretendard_Bold">
              {crewData.crewName}
            </div>
            <div className="text-[#8C8C8C]">
              <span>리더</span>
              <span> | </span>
              <span>{crewData.crewLeader.nickname}</span>
            </div>
            <div className="text-[#8C8C8C]">
              <span>{crewGender}</span>
            </div>
            <div className="mt-[24px] font-Pretendard_Regular">
              "{crewData.crewBio}"
            </div>
          </div>
        </div>
        {isLeader && (
          <div className="mt-[14px] mb-[16px] flex flex-col items-end gap-2">
            <button
              className="bg-pointColor rounded-xl w-[108px] h-[36px] py-[8px] pl-[12px] pr-[8px] text-black text-[14px] font-Pretendard_Bold flex items-center justify-center"
              onClick={handleInviteClick}
            >
              크루원 초대 <MdArrowOutward className="ml-[4px]" />
            </button>
          </div>
        )}
        <div className="py-5 items-center">
          <div className="flex justify-between gap-2 w-full">
            <div className="bg-cardBgColor w-[104px] h-[104px] rounded-xl pt-[16px] pb-[12px] px-[12px] flex flex-col justify-between">
              <div className="text-sm font-Pretendard_Regular text-[14px] top-0">
                경기 횟수
              </div>
              <div className=" bottom-0">
                <div className="opacity-70 font-Pretendard_Regular text-[10px]">
                  {crewData.matchCount}회
                </div>
                <div className="text-pointColor font-Montserrat font-bold text-[18px]">
                  {crewData.matchCount} {/* 경기 횟수 */}
                </div>
              </div>
            </div>

            <div className="bg-cardBgColor w-[104px] h-[104px] rounded-xl pt-[16px] pb-[12px] px-[12px] flex flex-col justify-between">
              <div className="text-sm font-Pretendard_Regular text-[14px] top-0">
                크루 순위
              </div>
              <div className=" bottom-0">
                <div className="opacity-70 font-Pretendard_Regular text-[10px]">
                  {crewData.rank ? `상위 ${crewData.rank}%` : "순위 없음"}
                </div>
                <div className="text-pointColor font-Montserrat font-bold text-[18px]">
                  {crewData.rank !== null ? `${crewData.rank}%` : "-"}
                </div>
              </div>
            </div>

            <div className="bg-cardBgColor w-[104px] h-[104px] rounded-xl pt-[16px] pb-[12px] px-[12px] flex flex-col justify-between">
              <div className="text-sm font-Pretendard_Regular text-[14px] top-0">
                크루 승률
              </div>
              <div className=" bottom-0">
                <div className="text-pointColor font-Montserrat font-bold text-[18px]">
                  {crewData.winRate}%
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-5">
          <div
            className="flex items-center justify-between mb-5 cursor-pointer"
            onClick={() => navigate(`/crew/detail/${detailId}/prevMatches`)} // 절대 경로로 수정
          >
            <div className="text-lg font-bold">지난 경기를 확인해 보세요.</div>
            <HiChevronRight className="text-3xl" />
          </div>
          <div className="flex items-center gap-3 overflow-x-scroll">
            <div className="flex gap-2">
              {prevMatches && prevMatches.length > 0 ? (
                prevMatches.map((match) => (
                  <div
                    key={match.id} // 고유 id로 key 설정
                    onClick={() => navigate(`/crew/match/${match.id}`)} // 클릭 시 절대 경로로 이동
                  >
                    <PrevGameCard {...match} /> {/* 각 경기에 대한 카드 표시 */}
                  </div>
                ))
              ) : (
                <div>이전 매치가 없습니다!</div>
              )}
            </div>
          </div>
        </div>
        <div className="py-5">
          <div className="text-lg font-bold">
            크루의 멤버들을 확인해 보세요.
          </div>
          <div className="flex items-center gap-3 overflow-x-scroll my-[16px]">
            <div className="flex gap-2">
              {crewData.memberList && crewData.memberList.length > 0 ? (
                crewData.memberList.map((member, index) => (
                  <PrevMemCard
                    key={index}
                    nickName={member.nickname}
                    style={
                      stylePreferenceMap[member.stylePreference] ||
                      member.stylePreference
                    }
                    score={member.rating}
                    profileImageUrl={
                      profileImageMap[member.profileImage] || defaultImg
                    }
                  />
                ))
              ) : (
                <div>크루원이 없습니다.</div>
              )}
            </div>
          </div>
        </div>
        {isMember && (
          <button
            onClick={openModal}
            className="py-6 mt-10 text-[16px] opacity-30 flex items-center justify-center gap-3"
          >
            크루 탈퇴
          </button>
        )}
        {isModalOpen && (
          <LeaveModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            onConfirm={handleLeaveCrew}
          />
        )}
      </div>
    </div>
  );
}
