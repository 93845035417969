import { useEffect, useState } from "react";
import { IoFootball, IoPerson } from "react-icons/io5";
import { MdGroups } from "react-icons/md";
import GatherInfo from "./GatherInfo";
import Kickers from "./Kickers";
import MatchRules from "./MatchRule";
import CompleteKicker from "./CompleteKicker";
import { matchInfoProps } from "../../page/match/match-detail";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import GameRule from "./GameRule";

interface matchInfoProps2 {
  matchDetail: matchInfoProps | null;
  isMatch: boolean; // boolean -> apply여부, 신청 했으면 false
}

export default function MatchInfo({ matchDetail, isMatch }: matchInfoProps2) {
  const [navNum, setNavNum] = useState(1);
  const [complete, setComplete] = useState(false);
  useEffect(() => {
    if (matchDetail) {
      const now = new Date();
      const matchTime = new Date(matchDetail?.matchTime);

      const dayDifference = matchTime.getDay() - now.getDay();
      const timeDifference = matchTime.getTime() - now.getTime();

      if (
        Math.floor((timeDifference / (1000 * 60 * 60)) % 24) < 2 &&
        dayDifference <= 0 &&
        matchDetail.playerCount >= 10
      ) {
        setComplete(true);
      }
    }
  }, [navNum]);

  return (
    <div className="flex flex-col items-center mb-14">
      <div className="flex justify-around py-[14px] w-full text-sm *:py-1">
        <div
          onClick={() => setNavNum(1)}
          className={
            navNum === 1 ? "border-b-2 border-pointColor" : "opacity-45"
          }
        >
          모집 정보
        </div>
        <div
          onClick={() => setNavNum(2)}
          className={
            navNum === 2 ? "border-b-2 border-pointColor" : "opacity-45"
          }
        >
          참여 키커
        </div>
        <div
          onClick={() => setNavNum(3)}
          className={
            navNum === 3 ? "border-b-2 border-pointColor" : "opacity-45"
          }
        >
          {isMatch ? "매칭 규칙" : "게임 규칙"}
        </div>
      </div>

      <div className="flex flex-col gap-4 py-2 w-full">
        {navNum === 1 ? (
          <GatherInfo matchDetail={matchDetail} />
        ) : navNum === 2 ? (
          <>{complete ? <CompleteKicker /> : <Kickers />}</>
        ) : (
          <>{isMatch ? <MatchRules /> : <GameRule />}</>
        )}
      </div>
    </div>
  );
}
