import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navigation from "./components/Navigation/navigation";
import { useRecoilValue } from "recoil";
import { searchClickState } from "./atom";

export default function Layout() {
  const isClick = useRecoilValue(searchClickState)

  return (
    <div className="layout">
      <div className="px-5  mb-24">
        <Outlet />
      </div>
      {isClick ? null : <Navigation />}




    </div>
  );
}
