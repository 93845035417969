import { ButtonHTMLAttributes } from "react";

interface ButtonProps {
  text: string;
  active?: boolean;
}

export default function Button({
  text,
  active,
  ...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps) {
  return (
    <>
      {active ? (
        <button
          {...rest}
          className={`${rest.className} w-full font-Pretendard_Bold btn bg-pointColor h-12 rounded-lg font-bold text-black hover:bg-pointColor`}
        >
          {text}
        </button>
      ) : (
        <button
          {...rest}
          className={`${rest.className} w-full  font-Pretendard_Bold bg-[#2C2C2C] text-[#8C8C8C] btn h-12 rounded-lg font-bold hover:bg-[#2C2C2C]`}
        >
          {text}
        </button>
      )}
    </>
  );
}
