import { RiHome5Fill } from "react-icons/ri";
import { FaRegCalendar } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import { MdOutlineSportsSoccer } from "react-icons/md";
import { IoMdPerson } from "react-icons/io";
import { useRecoilState, useRecoilValue } from "recoil";
import { navState } from "../../atom";

interface NavBtnProps {
  label: string;
  nav: string;
}

export default function NavBtn({ label, nav }: NavBtnProps) {
  const page = useRecoilValue(navState);

  const chooseIcon = () => {
    if (label === "홈") {
      return <RiHome5Fill className="text-2xl" />;
    } else if (label === "매칭") {
      return <FaRegCalendar className="text-2xl" />;
    } else if (label === "랭킹") {
      return <FaTrophy className="text-2xl" />;
    } else if (label === "장비대여") {
      return <MdOutlineSportsSoccer className="text-2xl" />;
    } else {
      return <IoMdPerson className="text-2xl" />;
    }
  };

  return (
    <div className={page === nav && label !== "장비대여" ? `selectedNavBtn` : `navBtn`}>
      {chooseIcon()}
      <div className="text-[10px]">{label}</div>
    </div>
  );
}
