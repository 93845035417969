import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Vest from "../../assets/rentImgs/vest.png"
import Retrurn_Vest from "../../assets/rentImgs/return_vest.png"
import Ball from "../../assets/rentImgs/ball.png"
import Return_Ball from "../../assets/rentImgs/return_ball.png"

export default function Rent() {
  const [rentType, setRentType] = useState<"vest" | "ball">("vest")

  return <div className="pageContainer">
    <div className="text-xl opacity-60 font-Pretendard_Bold pb-4">장비 대여</div>
    <div className="text-center py-4">
      WEKICK에서 대여해드리는 모든 물품은<br />
      <span className="font-Pretendard_Bold text-2xl">무료입니다.</span>
    </div>
    <div className="flex py-1 mb-4">
      <div
        className={`p-3 py-[6px] cursor-pointer ${rentType === "vest" ? "border-b-2 border-pointColor" : ""}`}
        onClick={() => setRentType("vest")}>조끼</div>
      <div
        className={`p-3 py-[6px] cursor-pointer ${rentType === "ball" ? "border-b-2 border-pointColor" : ""}`}
        onClick={() => setRentType("ball")}
      >공</div>
    </div>
    <div className="pb-8 space-y-4">
      <div className="bg-cardBgColor rounded-lg p-3">
        <div className="font-Pretendard_Bold">WEKICK 경기용 {rentType === "vest" ? "조끼" : "공"}을 사용하세요!</div>
        <div className="flex justify-center py-4">
          <img src={rentType === "vest" ? Vest : Ball} className="w-5/6 object-cover rounded-md" />
        </div>

        <div className="text-center text-sm">
          풋살장에 부착된 안내 및 <span className="text-warningColor">주의사항</span>을<br />
          반드시 숙지하고 플레이 하시기 바랍니다.
        </div>
      </div>
      <div className=" bg-cardBgColor rounded-lg p-3">
        <div>
          반납 방법
        </div>
        <div className="flex justify-center py-4">
          <img className="rounded-md w-5/6" src={rentType === "vest" ? Retrurn_Vest : Return_Ball} />
        </div>
        <div className="text-center text-sm">
          {rentType ?
            <Fragment>
              사용 후, 바람에 날아가지 않게 <br />
              사진처럼 다시 걸어주세요
            </Fragment>
            :
            <Fragment>
              경기 시에 사용하고 종료 후 <br />
              바구니에 다시 넣어주세요!
            </Fragment>
          }
        </div>

      </div>
    </div>
    <div className="text-center text-blurTextColor text-sm ">
      분실 및 파손 관련 문의는<br />
      인스타그램 @wekick_officail로 문의해주세요.
    </div>
  </div >;
}
