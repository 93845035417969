import React from "react";

const GameRule = () => {
  return (
    <div className="w-full px-8 flex flex-col">
      <div className="flex flex-col w-full gap-10">
        <div>
          <div className="my-2 text-[22px]">⚽ INTRO</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>풋살화와 개인 음료를 준비해 주세요.</li>
            <li>
              조끼와 공은 인스타그램 @wekick_official 의 DM으로 문의해 주세요!
              (10월부터 지원 가능)
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">🤹 경기 규칙 안내</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>경기는 RED 팀과 GREEN 팀으로 나뉘어 진행됩니다.</li>
            <li>경기는 2시간동안 진행됩니다.</li>
            <li>
              쿼터당 25분을 권장하지만, 팀 상호간의 합의를 통해 쿼터당 시간을
              변동해도 무관합니다!
            </li>
            <li>지정 키퍼가 없다면 ‘골넣으면킵 원칙’ </li>
            <li>
              이외 파울시 킥인 규칙 등은 팀 상호간 합의를 통해 자유롭게
              정하세요!
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">❌ 반칙</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              WEKICK 풋살 특성 상 심판이 없습니다. 상호간 매너있는 게임
              진행해주시고, 반칙이나 충돌 시 골킥 주는 센스!!
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">🪢 교체 & 공수 전환</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>팀 위치 교체와 공수 전환에도 정해진 규칙은 없습니다.</li>
            <li>두 쿼터 마다 세트교체를 권장합니다.</li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">⌛ 취소 시 페널티</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              경기 시작 시간으로부터 2시간 이하로 남았을 때부터 매칭을 취소하는
              경우, 페널티 점수로 ‘-10점’이 부여됩니다.
            </li>
            <li>
              페널티 점수는 개인별로 누적되며 -30점이 넘어가면 서비스 이용이
              제한될 수 있습니다.
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">💬 게임 후 매칭 평가</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              게임이 끝난 후, 진행된 매칭에 대한 평가는 필수로 진행해야 합니다.
            </li>
            <li>
              모든 쿼터를 포함하여도 좋고, 특정 쿼터만을 기준으로 점수를 매겨도
              좋습니다. 단, 경기종료 이후 진행되는 경기평가에서 모두 동일한 값을
              입력해야함을 유념하여 경기 종료 시 각팀 스코어에 대한 상호간
              체킹을 꼭 해주세요.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default GameRule;
