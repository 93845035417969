import { useForm } from "react-hook-form";
import { IoSearchSharp } from "react-icons/io5";
import { Fragment, useEffect, useState } from "react";
import RenderRankingType, { RankingCrewDataType, RankingPlayerDataType } from "../../components/RankCopmos/renderRankingType";
import { IoIosArrowBack } from "react-icons/io";
import { useRecoilState } from "recoil";
import { searchClickState, rankTypeState, rankPageState } from "../../atom";
import PlayerCard from "../../components/RankCopmos/PlayerCard";
import axios from "axios";
import { ProfileImage } from "../../components/RankCopmos/PlayerType";
import { Link } from "react-router-dom";

interface validType {
  search: string
}

export type SearchPlayerType = {
  id: number
  matchCount: 2
  nickName: ProfileImage
  profileImage: string
  ranking: number
  rating: number
}

export type SearchCrewType = {
  id: number
  matchCount: number
  name: string
  ranking: number
  rating: number
  profileImage: ProfileImage
}

type clickType = {
  type: "crew" | "player"
}

type SearchDataType<T> = T extends "player" ? SearchPlayerType : SearchCrewType;

export default function Rank() {
  const [type, setType] = useRecoilState(rankTypeState)
  const [isClickedSearch, setIsClicked] = useRecoilState(searchClickState)
  const { register, watch, handleSubmit, reset } = useForm<validType>()
  const [searchCards, setSearchCards] = useState<SearchDataType<typeof type>[]>([])
  const [page, setPage] = useRecoilState(rankPageState)

  const onValid = ({ search }: validType) => {
    const api = `${process.env.REACT_APP_API_URL}/${type === "player" ? "search-member" : "search-crew"}?keyword=${search}`

    axios.get(api).then(res => {
      const data = res.data.data.members || res.data.data.crews
      if (data.length === 0) alert("검색결과가 없습니다!")
      setSearchCards(data)
    }).catch(err => console.log(err))
  }

  const onClickType = ({ type }: clickType) => {
    setType(type)
    setPage(0)
  }
  useEffect(() => {
    setSearchCards([]);
  }, [watch("search")]);

  return <div className="pageContainer  ">
    <div className={`${isClickedSearch ? "flex items-center py-1 space-x-3" : ""} `}>


      {isClickedSearch ?
        <IoIosArrowBack size={20} onClick={() => {
          setIsClicked(false)
          setSearchCards([])
          reset()
        }} color="#A1A1A1" />
        :
        <Fragment>
          <div className="text-xl opacity-60 font-Pretendard_Bold pb-4">랭킹</div>
          <div className="flex py-1 mb-4">
            <div
              className={`p-3 py-[6px] ${type === "player" ? "border-b-2 border-pointColor" : ""}`}
              onClick={() => onClickType({ type: "player" })}>개인</div>
            <div
              className={`p-3 py-[6px] ${type === "crew" ? "border-b-2 border-pointColor" : ""}`}
              onClick={() => onClickType({ type: "crew" })}
            >크루</div>
          </div>
        </Fragment>
      }


      <form onSubmit={handleSubmit(onValid)} className="relative group flex-grow" onClick={() => setIsClicked(true)}>
        <input className="group-hover:transition-all w-full input  bg-cardBgColor placeholder:text-[#6D6D6D] pl-10 " {...register("search")} placeholder={`${type === 'player' ? "키커" : "크루"} 검색`} />
        <button className="absolute left-3 top-[13px]">
          <IoSearchSharp size={20} color="#6D6D6D" />
        </button>
      </form>
    </div>
    {isClickedSearch
      ?
      watch("search") === "" ?

        <div className="mt-12 text-center opacity-50">
          찾는 {type === "player" ? "플레이어와 닉네임" : "크루의 이름"}을 검색해주세요
        </div>
        :
        <div className="pt-4">
          {searchCards.map((item, index) =>
            <Link to={type === "player" ? `/player/detail/${item.id}` : `/crew/detail/${item.id}`} key={index}>
              <PlayerCard  {...item} />
            </Link>

          )}
        </div>
      : <RenderRankingType />
    }


  </div>;
}
