import React from "react";

interface MemCardProps {
  nickName: string;
  style: string;
  score: number;
  profileImageUrl: string; // 프로필 이미지 URL 추가
}

const PrevMemCard: React.FC<MemCardProps> = ({ nickName, style, score, profileImageUrl }) => {
  return (
    <div className="w-[120px] h-[128px] bg-cardBgColor rounded-lg flex flex-col p-[8px] text-white">
      <div className="flex justify-between items-start mb-[8px]">
        {/* 프로필 이미지 추가 */}
        <div className="w-[46px] h-[46px] bg-[#434343] rounded-lg">
          <img
            src={profileImageUrl}
            alt={`${nickName} 프로필 이미지`}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        {/* 크루 참가 버튼 */}
        {/* <div className="bg-[#434343] rounded-lg px-[10px] py-[3px] text-[8px]">
          크루 참가
        </div> */}
      </div>
      <div className="font-bold mb-[2px] font-Pretendard_SemiBold text-[14px]">
        {nickName}
      </div>
      <div className="flex mb-[5px] text-[10px] space-x-[5px]">
        <div className="text-white opacity-70 font-Pretendard_Regular">
          경기 스타일
        </div>
        <div className="text-white font-Pretendard_Regular">
          {style}
        </div>
      </div>
      <div className="text-pointColor font-Pretendard_SemiBold text-[13px] mb-[7px]">
        {score}점
      </div>
    </div>
  );
};

export default PrevMemCard;
