import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import "./CalendarWrap.css"
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { calendarViewState, selectedDateState } from '../../atom';

const dayOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

const CalendarWrap = () => {
    const [view, setView] = useRecoilState(calendarViewState)
    const today = moment()
    const [selectedMonth, setSelectedMonth] = useState<moment.Moment>(moment())
    const [selectedDate, setSelectedDate] = useRecoilState(selectedDateState)
    const [days, setDays] = useState<moment.Moment[]>([])
    const [activeDayList, setAtiveDayList] = useState<string[]>([""])

    const handleSwitchView = () => {
        setView(view === 'week' ? 'month' : 'week');
        setSelectedMonth(today.clone())
    };

    const handlePrevMonth = () => {
        setSelectedMonth(selectedMonth.clone().startOf('month').subtract(1, 'month'));
        setView('month')
    };

    const handleNextMonth = () => {
        setSelectedMonth(selectedMonth.clone().startOf('month').add(1, 'month'));
        setView('month')
    };

    const isToday = (day: moment.Moment) => {
        return day.clone().format("YYYY-MM-DD") === today.clone().format("YYYY-MM-DD")
    }

    const isSelectedday = (day: moment.Moment) => {
        return day.clone().format("YYYY-MM-DD") === selectedDate.clone().format("YYYY-MM-DD")
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/matches/dates`).then(res => {
            setAtiveDayList(res.data.data.dates)
        }).catch(err => console.log(err))
    }, [])

    useEffect(() => {
        const getDays = () => {
            const days = [];
            if (view === 'week') {
                // selectedMonth를 기준으로 그 주의 시작일(월요일)과 끝일(일요일)을 계산
                const startOfWeek = today.clone().startOf('isoWeek');
                const endOfWeek = today.clone().endOf('isoWeek');

                // 해당 주의 모든 날을 days 배열에 추가
                for (let day = startOfWeek; day.isBefore(endOfWeek); day.add(1, 'day')) {
                    days.push(day.clone());
                }
            } else {
                // 월의 전체 일 수를 추가
                for (let day = selectedMonth.clone().startOf('month').startOf("isoWeek");
                    day.isBefore(selectedMonth.clone().endOf('month'));
                    day.add(1, 'day')) {
                    days.push(day.clone());
                }
            }
            return days;
        };
        const caclDays = getDays()
        setDays(caclDays)
    }, [selectedMonth, view])



    return (
        <div className=' relative'  >
            <div className='flex justify-center items-center space-x-4 font-Pretendard_Bold text-xl sm:w-full sm:static absolute -left-[18px] w-screen py-3 bg-black'>
                <span className='opacity-70' onClick={handlePrevMonth}>
                    <FaChevronLeft />
                </span>
                <span className='opacity-70'>
                    {selectedMonth.format("MMMM")}
                </span>
                <span className='opacity-70' onClick={handleNextMonth}>
                    <FaChevronRight />
                </span>

            </div>
            <div className='grid grid-cols-7 mt-12 sm:mt-0 py-5 justify-items-center place-items-center '>
                {dayOfWeek.map((day, index) => <div key={day} className={`text-xs text-[#7F7F7F]  w-10 text-center py-2 mx-1
                    ${view === "week" && today.clone().isoWeekday() === index + 1 && today.clone().format("YYYY-MM-DD") === today.clone().format("YYYY-MM-DD") ? "week-isToday-weekday" : ""}


                    `}
                    onClick={handleSwitchView}
                >{day}</div>)}
                {days.map((day, index) => (
                    <span key={index} className={` flex justify-center items-center mx-1 p-2 px-3 
                        ${isToday(day) && view === "week" ? "week-isTody-day" : ""}

                        ${isSelectedday(day) && view === "month" ? "month-active-day" : ""}

            
                        ${view === "month" && day.clone().format("MMMM") !== selectedMonth.clone().format("MMMM") ? "opacity-0" : ""}

                        ${activeDayList.includes(day.clone().format("YYYY-MM-DD")) ? "text-white" : "text-white/40 "}
                        
                        `}
                        onClick={() => {
                            if (view === "week") {
                                handleSwitchView()
                            } else {
                                setSelectedDate(day)
                            }
                        }}
                    >{day.clone().format("DD")}</span>
                ))}
            </div>
        </div>
    );
};

export default CalendarWrap;