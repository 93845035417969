import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function GoBack() {
  const navigate = useNavigate();
  return (
    <div className="px-[16px]">
      <FaChevronLeft
        onClick={() => {
          if (window.history.state.idx <= 0) {
            navigate("/match");
          } else {
            navigate(-1);
          }
        }}
        className="text-[18px]"
      />
    </div>
  );
}
