import React from 'react';
import CrownPoint from "../../assets/crowns/crown_point.png"
import CrownDefault from "../../assets/crowns/corwn_default.png"
import { RankingCrewDataType, RankingPlayerDataType } from './renderRankingType';

import ProfileImg1 from "../../assets/ProfileImgs/ProfileImg1.png";
import ProfileImg2 from "../../assets/ProfileImgs/ProfileImg2.png";
import ProfileImg3 from "../../assets/ProfileImgs/ProfileImg3.png";
import ProfileImg4 from "../../assets/ProfileImgs/ProfileImg4.png";
import { ProfileImage } from './PlayerType';

import CrewImg from "../../assets/default.png"

type TopRankedCardType = RankingPlayerDataType | RankingCrewDataType;


const TopRankedCard = (item: TopRankedCardType) => {

    const renderProfileImg = (profileImg: string) => {
        switch (profileImg) {
            case ProfileImage.RED: return ProfileImg1
            case ProfileImage.YELLOW: return ProfileImg2
            case ProfileImage.BLUE: return ProfileImg3
            case ProfileImage.GREEN: return ProfileImg4
            default: return ProfileImg1
        }
    }

    return (
        <div className={`flex flex-col items-center ${item.ranking === 1 ? "pb-[18px]" : ""}`}>
            <img alt="crown" src={item.ranking === 1 ? CrownPoint : CrownDefault} />
            <div className='btn bg-cardBgColor w-full pt-3 pb-2 mt-2 h-full'>
                <div className='flex flex-col items-center justify-center'>
                    <p className={`font-Montserrat font-bold text-2xl opacity-80 ${item.ranking === 1 ? "text-pointColor" : ""}`}>{item.ranking}</p>
                    <img src={`${'nickName' in item ? renderProfileImg(item.profileImage) : CrewImg}`} className='rounded-full h-10 w-10 mt-2 mb-3 mx-[30px]' />
                    <div className='text-center text-xs'>{'nickName' in item ? item.nickName : item.name}</div>
                    <p className='font-Pretendard_Bold text-pointColor mt-4 mb-2'>
                        {item.rating}점
                    </p>
                    <p className=' text-[10px] text-[#626262]'>경기 수 {item.matchCount}</p>
                </div>

            </div>
        </div>
    );
};

export default TopRankedCard;