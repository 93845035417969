import axios from "axios";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";

export default function CompleteKicker() {
  const [teams, setTeams] = useState({
    teamList: [
      {
        teamName: "RED",
        memberList: [
          { nickName: "팀 배정중", isSubstitute: false },
          { nickName: "팀 배정중", isSubstitute: true },
        ],
      },
      {
        teamName: "GREEN",
        memberList: [
          { nickName: "팀 배정중", isSubstitute: false },
          { nickName: "팀 배정중", isSubstitute: true },
        ],
      },
    ],
  });
  const { matchId } = useParams();
  const [cookies] = useCookies();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/matches/${matchId}/teams`, {
        headers: { Authorization: `Bearer ${cookies.accessToken}` },
      })
      .then((res) => {
        setTeams(res.data.data);
      })
      .catch((e) => console.log(e));
  }, []);
  return (
    <div className="px-5 flex flex-col gap-3">
      <div>
        함께 운동할 우리 팀을 <br />
        확인해보세요!
      </div>

      <>
        {teams.teamList.length > 0 ? (
          <>
            {teams.teamList.map((team, i) => (
              <div
                key={i}
                className="px-3 py-2 bg-cardBgColor rounded-xl flex flex-col gap-1"
              >
                <div>
                  TEAM{" "}
                  <span
                    className={
                      team.teamName === "RED"
                        ? "text-red-500"
                        : "text-green-400"
                    }
                  >
                    {team.teamName}
                  </span>
                </div>
                <div className="flex items-center">
                  <div className="bg-black px-2 py-1 text-[10px] rounded-md mr-2">
                    선발
                  </div>
                  <div className="text-sm flex gap-1">
                    {team.memberList.map((member, i) => (
                      <>
                        {!member.isSubstitute ? (
                          <div className="max-w-[60px] h-[20px]" key={i}>
                            {member.nickName
                              .replaceAll(" ", "")
                              .substring(0, 4)}
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
                <div className="flex items-center">
                  <span className="bg-black px-2 py-1 text-[10px] rounded-md mr-2">
                    후보
                  </span>
                  <div className="text-sm opacity-50 flex gap-1">
                    {team.memberList.map((member, i) => (
                      <>
                        {member.isSubstitute ? (
                          <div
                            className="max-w-[40px] h-[20px] overflow-hidden text-ellipsis"
                            key={i}
                          >
                            {member.nickName
                              .replaceAll(" ", "")
                              .substring(0, 4)}
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div>참여 키커에 대한 정보가 없어요.</div>
        )}
      </>
    </div>
  );
}
