import React from "react";
import { UseFormRegister } from "react-hook-form";
import { InputRegisterType } from "./inputRegisterType";

interface InputTextType extends InputRegisterType {
  validation?: {
    pattern?: { value: RegExp; message: string };
  };
}

const InputText = ({
  registerName,
  register,
  watch,
  validation,
  ...rest
}: React.InputHTMLAttributes<HTMLInputElement> & InputTextType) => {
  return (
    <input
      {...register(registerName, {
        required: true,
        ...validation,
      })}
      {...rest}
      className={`input w-full focus:outline-none  ${rest.className}`}
    />
  );
};

export default InputText;
