import axios from "axios";
import { Dispatch, SetStateAction } from "react";
import { useCookies } from "react-cookie";
import { AiFillExclamationCircle } from "react-icons/ai";
import { HiXMark } from "react-icons/hi2";
import { useParams } from "react-router-dom";

interface CancelModalProps {
  setIsMatch: Dispatch<SetStateAction<boolean>>;
  setIsModal: Dispatch<SetStateAction<boolean>>;
}

export default function CancelModal({
  setIsMatch,
  setIsModal,
}: CancelModalProps) {
  const [cookies] = useCookies();
  const { matchId } = useParams();
  const onCancel = () => {
    axios
      .patch(
        `${process.env.REACT_APP_API_URL}/matches/${matchId}/cancel`,
        { data: null },
        {
          headers: { Authorization: `Bearer ${cookies.accessToken}` },
        }
      )
      .then((res) => {
        alert("취소되었습니다.");
        setIsModal(false);
      })
      .catch((e) => {
        alert(
          "매칭 취소에 실패했어요. 다시 시도해주세요.\n만약 문제가 반복되면 운영팀에 문의해주세요."
        );

        setIsMatch(false);
      })
      .finally(() => window.location.reload());
  };
  return (
    <div className="fixed z-50 top-0 left-0 flex justify-center items-center px-1 py-2 bg-black bg-opacity-85 w-full h-full">
      <div className="bg-cardBgColor w-[80%] h-[50%] px-2 py-4 flex flex-col justify-between rounded-2xl">
        <div
          className="flex justify-end px-2 w-full"
          onClick={() => {
            setIsMatch(false);
            setIsModal(false);
          }}
        >
          <HiXMark className="size-5 hover:bg-pointColor hover:text-black rounded-full" />
        </div>
        <div className="flex flex-col justify-center items-center gap-5">
          <div>
            <AiFillExclamationCircle className="size-14 fill-[#484848]" />
          </div>
          <div className="text-xl text-center">
            정말로 <span className="text-pointColor">취소</span>
            하시겠습니까?
            <div className="text-sm opacity-35">
              신청 내용들은 모두 초기화됩니다.
            </div>
          </div>
        </div>
        <div className="flex items-center justify-around">
          <button
            onClick={() => {
              setIsMatch(false);
              setIsModal(false);
            }}
            className="w-[45%] bg-[#505050] text-opacity-75 rounded-lg py-3"
          >
            닫기
          </button>
          <button
            onClick={() => {
              onCancel();
            }}
            className="w-[45%] bg-pointColor text-black rounded-lg py-3"
          >
            취소하기
          </button>
        </div>
      </div>
    </div>
  );
}
