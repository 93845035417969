import React from "react";
import { BsExclamationCircleFill } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";

interface LeaveModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void; // 추가된 prop
}

const LeaveModal: React.FC<LeaveModalProps> = ({ isOpen, closeModal, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-[#363636] rounded-lg w-[328px] h-[311px] p-4 flex flex-col justify-between items-center relative">
        <div className="mb-[10px]">
          <button
            onClick={closeModal}
            className="absolute top-4 right-4 text-gray-400 hover:text-white mb-10"
          >
            <IoMdClose className="text-[24px]" />
          </button>
        </div>

        <div className="text-[#484848] mt-[8px]">
          <BsExclamationCircleFill className="text-[56px]" />
        </div>

        <p className="text-center text-[20px] my-4">
          정말로{" "}
          <span className="font-Pretendard_SemiBold text-pointColor">탈퇴</span>
          하시겠습니까?
        </p>

        <div className="text-center font-Pretendard_Regular opacity-70 mb-[31px]">
          <p>탈퇴하시면 지금 크루원들과 같이</p>
          <p>경기하기 어려워질 수 있어요!</p>
        </div>

        <div className="flex justify-end gap-3 font-Pretendard_Bold w-full">
          <button
            onClick={closeModal}
            className="bg-[#505050] px-4 py-2 rounded-md w-[142px] h-[48px]"
          >
            닫기
          </button>
          <button
            onClick={onConfirm} // 탈퇴하기 버튼을 누르면 onConfirm 호출
            className="bg-pointColor text-black px-4 py-2 rounded-md w-[142px] h-[48px]"
          >
            탈퇴하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveModal;
