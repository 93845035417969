import { FaCamera, FaChevronDown, FaUser } from "react-icons/fa";
import GoBack from "../../components/Navigation/GoBack";
import { HiChevronRight, HiXMark } from "react-icons/hi2";
import { useEffect, useState } from "react";
import Modal from "../../components/MyPageModal";
import RED from "../../assets/ProfileImgs/ProfileImg1.png";
import YELLOW from "../../assets/ProfileImgs/ProfileImg2.png";
import BLUE from "../../assets/ProfileImgs/ProfileImg3.png";
import GREEN from "../../assets/ProfileImgs/ProfileImg4.png";
import InputCircleSelect from "../../components/Input/InputCircleSelect";
import InputCheckBoxSelect from "../../components/Input/InputCheckBoxSelect";
import axios from "axios";
import { userProps } from "./mypage";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function ChangeUserInfo() {
  const [userName, setUserName] = useState("손흥민");
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false); // Bottom sheet를 위한 상태
  const [userSex, setUserSex] = useState<string | number>("여성");
  const [userPosition, setUserPosition] = useState<string | number>("공격");
  const [userSkills, setUserSkills] = useState<(string | number)[]>(["슛"]);
  const [userEmail, setUserEmail] = useState("example@example.com");
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [crewInfo, setCrewInfo] = useState<crewInfoProps | null>(null);
  const [cookies] = useCookies();
  const navigate = useNavigate();

  interface crewInfoProps {
    crewId: number;
    crewName: string;
    rank: number;
    rating: number;
    winRate: number;
  }
  interface modifyInfo {
    profileImage?: string;
    nickname?: string;
    sex?: string;
    stylePreference?: string;
    skill?: (string | undefined)[];
  }

  const skillEnum = {
    SHOT: "슛",
    SPEED: "스피드",
    DRIBBLE: "드리블",
    PHYSICAL: "피지컬",
    STAMINA: "지구력",
    GOALKEEPER: "골키퍼",
  };
  const sexEnum = {
    MALE: "남성",
    FEMALE: "여성",
  };
  const styleEnum = {
    ATTACK: "공격",
    DEFENSE: "수비",
  };

  const getSexByValue = (
    value: string | number
  ): keyof typeof sexEnum | undefined => {
    return (Object.keys(sexEnum) as Array<keyof typeof sexEnum>).find(
      (key) => sexEnum[key] === value
    );
  };

  const getStyleByValue = (
    value: string | number
  ): keyof typeof styleEnum | undefined => {
    return (Object.keys(styleEnum) as Array<keyof typeof styleEnum>).find(
      (key) => styleEnum[key] === value
    );
  };

  const getSkillByValue = (
    value: string | number
  ): keyof typeof skillEnum | undefined => {
    return (Object.keys(skillEnum) as Array<keyof typeof skillEnum>).find(
      (key) => skillEnum[key] === value
    );
  };

  const modifyNickName = async (props: modifyInfo) => {
    const isAvailable = await axios
      .get(`${process.env.REACT_APP_API_URL}/members`, {
        params: { nickname: props.nickname },
        headers: { Authorization: `Bearer ${cookies.accessToken}` },
      })
      .then((res) => {
        return res.data.data.isAvailable;
      })
      .catch((e) => console.log(e));
    if (isAvailable) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/members/info`,
          {
            sex: props.sex,
            profileImage: props.profileImage,
            nickname: props.nickname,
            stylePreference: props.stylePreference,
            skill: props.skill,
          },
          {
            headers: { Authorization: `Bearer ${cookies.accessToken}` },
          }
        )
        .then((res) => {
          alert("변경되었습니다.");
        })
        .catch((e) => console.log(e));
    } else {
      alert("이미 존재하는 닉네임입니다.");
      window.location.reload();
    }
  };

  const modifyProfile = (props: modifyInfo) => {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/members/info`,
        {
          sex: props.sex,
          profileImage: props.profileImage,
          nickname: props.nickname,
          stylePreference: props.stylePreference,
          skill: props.skill,
        },
        {
          headers: { Authorization: `Bearer ${cookies.accessToken}` },
        }
      )
      .then((res) => {
        alert("변경되었습니다.");
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/members/info`, {
        headers: {
          Authorization: `Bearer ${cookies.accessToken}`,
        },
      })
      .then((res) => {
        setUserEmail(res.data.data.email);
        setUserName(res.data.data.nickname);
        if (res.data.data.sex === "MALE") {
          setUserSex("남성");
        } else {
          setUserSex("여성");
        }
        let temp = [];
        for (let i = 0; i < res.data.data.skill!.length; i++) {
          const key: keyof typeof skillEnum = res.data.data.skill[i];
          temp.push(skillEnum[`${key}`]);
        }
        setUserSkills(temp);
        setProfileImage(res.data.data.profileImage);
        setCrewInfo(res.data.data.crewRanking);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleUserSex = (s: string | number) => {
    setUserSex(s);
    const sex = getSexByValue(s);
    modifyProfile({ sex: sex });
  };

  const handleUserPosition = (position: string | number) => {
    setUserPosition(position);
    const style = getStyleByValue(position);
    modifyProfile({ stylePreference: style });
  };

  const handleUserSkills = (skills: (string | number)[]) => {
    setUserSkills(skills);
    let tempSkills = [];
    for (let i = 0; i < skills.length; i++) {
      tempSkills.push(getSkillByValue(skills[i]));
    }
    modifyProfile({ skill: tempSkills });
  };

  const selectProfileImage = (type: string | null) => {
    if (type === "RED") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${RED})`,
          }}
        />
      );
    } else if (type === "YELLOW") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${YELLOW})`,
          }}
        />
      );
    } else if (type === "BLUE") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${BLUE})`,
          }}
        />
      );
    } else if (type === "GREEN") {
      return (
        <div
          className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl bg-center bg-cover"
          style={{
            backgroundImage: `url(${GREEN})`,
          }}
        />
      );
    } else {
      return (
        <div className="bg-[#262626] bg-opacity-65 size-[132px] flex justify-center items-center rounded-3xl">
          <FaUser className="opacity-10 text-[40px]" />
        </div>
      );
    }
  };

  return (
    <div className="min-h-screen flex flex-col py-6 px-5">
      <GoBack />
      <div className="pageContainer px-5">
        <div className="flex flex-col items-center py-10">
          {selectProfileImage(profileImage)}

          <label
            htmlFor="file"
            className="cursor-pointer bg-[#373737] size-8 flex justify-center items-center rounded-full relative bottom-5 left-14"
            onClick={() => setIsBottomSheetOpen(true)}
          >
            <FaCamera className="text-md opacity-50" />
            <input
              type="file"
              name="avatar"
              id="avatar"
              className="hidden"
              accept="image/*"
            />
          </label>
        </div>

        <div className="pb-10 flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            <span>이름</span>
            <input
              type="text"
              className="rounded-lg bg-cardBgColor focus:outline-2 placeholder:text-neutral-500 focus:outline-pointColor px-3 h-[48px] text-neutral-500"
              placeholder={userName}
              onBlur={(e) => {
                if (e.target.value !== "") {
                  if (e.target.value.length >= 3) {
                    const ok = window.confirm(
                      `닉네임을 ${e.target.value}로 변경하시겠습니까?`
                    );
                    if (ok) {
                      setUserName(e.target.value);
                      modifyNickName({ nickname: e.target.value });
                    }
                  } else {
                    alert("닉네임은 3글자 이상의 길이어야 합니다.");
                  }
                }
              }}
            />
          </div>
          <div className="flex flex-col gap-2">
            <span>성별</span>
            <InputCircleSelect
              selectedItem={userSex}
              onItemChange={handleUserSex}
              title="성별"
              itemList={["남성", "여성"]}
              placeholder=""
            />
          </div>
          <div className="flex flex-col gap-2">
            <span>포지션</span>
            <InputCircleSelect
              selectedItem={userPosition}
              onItemChange={handleUserPosition}
              title="포지션"
              itemList={["공격", "수비"]}
              placeholder=""
            />
          </div>
          <div className="flex flex-col gap-2">
            <span>자신있는 능력</span>

            <InputCheckBoxSelect
              selectedItems={userSkills}
              onItemChange={handleUserSkills}
              title="크루의 자신있는 능력"
              itemList={[
                "슛",
                "드리블",
                "피지컬",
                "스피드",
                "지구력",
                "골키퍼",
              ]}
              placeholder="크루의 자신있는 능력"
              modalHeight="336px"
            />
          </div>
          <div className="flex flex-col gap-2">
            <span>이메일</span>
            <input
              type="text"
              className="rounded-lg bg-cardBgColor placeholder:text-neutral-500 focus:outline-2 focus:outline-pointColor px-3 h-[48px]"
              placeholder={userEmail}
              disabled
            />
          </div>
        </div>

        <div className="my-3">
          <div className="border-8 absolute left-0 w-screen border-[#161616]" />
        </div>

        <div className="flex flex-col gap-2 py-5 pb-10">
          <span>크루</span>
          {crewInfo?.crewName ? (
            <div
              onClick={() => navigate(`/crew/detail/${crewInfo?.crewId}`)}
              className="rounded-lg active:scale-[0.95] transition bg-cardBgColor focus:outline-2 focus:outline-pointColor px-3 flex items-center justify-between text-md py-3"
            >
              <span className="opacity-50">
                {crewInfo?.crewName ?? "참여한 크루가 없어요."}
              </span>
              <HiChevronRight className="opacity-50" />
            </div>
          ) : (
            <div>참여한 크루가 없어요.</div>
          )}
        </div>
      </div>

      {isBottomSheetOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-end">
          <div className="bg-[#2B2B2B] p-4 rounded-t-2xl w-full max-w-md h-[432px]">
            <div className="flex justify-between items-center mb-3">
              <span className="text-[20px] font-Pretendard_Bold ml-4">
                프로필 일러스트 선택
              </span>
              <HiXMark
                className="size-6 cursor-pointer mr-4"
                onClick={() => setIsBottomSheetOpen(false)}
              />
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-4 pt-[20px]">
              <div
                className="flex justify-center items-center rounded-lg w-[156px]"
                onClick={() => {
                  modifyProfile({ profileImage: "RED" });
                  setProfileImage("RED");
                  setIsBottomSheetOpen(false);
                }}
              >
                <img src={RED} alt="Profile Img 1" className="rounded-lg" />
              </div>
              <div
                className="flex justify-center items-center rounded-lg w-[156px]"
                onClick={() => {
                  modifyProfile({ profileImage: "YELLOW" });
                  setProfileImage("YELLOW");
                  setIsBottomSheetOpen(false);
                }}
              >
                <img src={YELLOW} alt="Profile Img 2" className="rounded-lg" />
              </div>
              <div
                className="flex justify-center items-center rounded-lg w-[156px]"
                onClick={() => {
                  modifyProfile({ profileImage: "BLUE" });
                  setProfileImage("BLUE");
                  setIsBottomSheetOpen(false);
                }}
              >
                <img src={BLUE} alt="Profile Img 3" className="rounded-lg" />
              </div>
              <div
                className="flex justify-center items-center  rounded-lg w-[156px]"
                onClick={() => {
                  modifyProfile({ profileImage: "GREEN" });
                  setProfileImage("GREEN");
                  setIsBottomSheetOpen(false);
                }}
              >
                <img src={GREEN} alt="Profile Img 4" className="rounded-lg" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
