import React, { useState } from "react";
import "./inputLabel.css";
import { InputRegisterType } from "./inputRegisterType";

interface InputRadioType extends InputRegisterType {
  radios: string[];
}

const InputRadio = ({ registerName, radios, register }: InputRadioType) => {
  const [selected, setSelected] = useState<string | null>(null);

  return (
    <div className="inputLabelWrap">
      {radios.map((radio, index) => (
        <label
          key={index}
          className={`inputLabel ${selected === radio ? "selcted" : ""} `}
          onClick={() => setSelected(radio)}
        >
          <input
            type="radio"
            {...register(registerName, { required: true })}
            id={radio}
            value={radio}
            className="hidden"
          />
          {radio}
        </label>
      ))}
    </div>
  );
};

export default InputRadio;
