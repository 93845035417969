import React from "react";
import { IoPersonSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { MatchGameCardType, MatchSexType } from "../atom";

const MatchGameCard = (game: MatchGameCardType) => {
  const matchType =
    game.matchType === MatchSexType.MALE
      ? "남성"
      : game.matchType === MatchSexType.FEMALE
      ? "여성"
      : "혼성";
  return (
    <Link
      to={`/match/detail/${game.matchId}`}
      className="bg-cardBgColor btn w-full text-white p-0 h-full"
    >
      <div className="flex p-2 items-center">
        <div className="font-Montserrat font-bold text-2xl px-3">
          {game.matchTime.slice(11)}
        </div>
        <div className="text-start flex-1 border-l border-[#4E4E4E] px-2 space-y-1">
          <p className="font-Pretendard_Bold ">{game.matchGround}</p>
          <p className="text-blurTextColor">{matchType} 경기</p>
          <div className="flex items-center space-x-1 text-blurTextColor text-[10px]">
            <IoPersonSharp />
            <p>10-18명</p>
          </div>
          <p
            className={`text-end font-Pretendard_SemiBold text-blurTextColor ${
              game.playerCount >= 10 ? "text-pointColor" : ""
            }`}
          >
            현재 모집 {game.playerCount}명
          </p>
        </div>
      </div>
      <div className="flex w-full">
        <div
          className={`h-2 w-2/3 bg-[#525252] rounded-bl border-r border-[#3A3A3A] flex`}
        >
          {Array(game.playerCount >= 10 ? 10 : game.playerCount)
            .fill(1)
            .map((item, index) => (
              <div
                key={index}
                className={`h-full w-[10%] bg-[#CDDD8A] ${
                  index === 0 ? "rounded-bl" : ""
                }`}
              />
            ))}
        </div>
        <div className="h-2 w-1/3 rounded-br bg-[#525252] flex ">
          {Array(game.playerCount >= 10 ? game.playerCount - 10 : 0)
            .fill(1)
            .map((item, index) => (
              <div
                key={index}
                className={`h-full w-[12.5%] bg-pointColor ${
                  index === 7 ? "rounded-br" : ""
                }`}
              />
            ))}
        </div>
      </div>
    </Link>
  );
};

export default MatchGameCard;
