import { IoFootball, IoPerson } from "react-icons/io5";
import { MdGroups } from "react-icons/md";
import { matchInfoProps } from "../../page/match/match-detail";
import { useEffect, useState } from "react";
import { match } from "assert";

interface matchInfoProps2 {
  matchDetail: matchInfoProps | null;
}

export default function GatherInfo({ matchDetail }: matchInfoProps2) {
  return (
    <div className="w-full px-[16px] py-5 flex flex-col gap-3 mb-14">
      <div className="flex justify-between">
        <div className="flex gap-3 items-center">
          <IoPerson className="text-xl" />
          <div className="flex flex-col">
            <span>현재 모집 인원 {matchDetail?.playerCount}명</span>
            <span className="text-[10px] opacity-45">
              {Number(matchDetail?.playerCount) < 10 ? (
                <div>
                  *앞으로 최소 {10 - Number(matchDetail?.playerCount)}명만
                  모이면 경기가 시작됩니다!
                </div>
              ) : (
                <div>
                  *매칭이 성공했습니다! 앞으로{" "}
                  {14 - Number(matchDetail?.playerCount)}명 더 신청 가능합니다.
                </div>
              )}
            </span>
          </div>
        </div>
        <span className="text-[13px] opacity-45">{matchDetail?.matchType}</span>
      </div>

      <div className="flex items-center justify-start gap-3">
        <MdGroups className="text-xl" />
        <div>크루 참여</div>
        <div className="opacity-45 text-[13px] w-[200px]">
          {matchDetail?.crewList ? (
            matchDetail.crewList.length > 0 ? (
              <div>
                {matchDetail.crewList.map((crew, i) => (
                  <span key={i}>'{crew}' </span>
                ))}
                크루 멤버들이 참여하고 있어요!
              </div>
            ) : (
              <div>참여중인 크루가 없습니다.</div>
            )
          ) : (
            <div>loading..</div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-start gap-3">
        <IoFootball className="text-xl" />
        <div>장비보유</div>
        {matchDetail?.isEquipped ? (
          <div className="opacity-45 text-[13px]">
            누군가 공을 갖고있습니다!
          </div>
        ) : (
          <div className="opacity-45 text-[13px]">
            공만 챙겨주세요!
            <br /> 조끼는 @wekick_official 로 DM 주시면
            <br /> 위킥이 빌려드릴게요!
          </div>
        )}
      </div>
    </div>
  );
}
