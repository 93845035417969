import React, { useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import RED from "../../assets/ProfileImgs/ProfileImg1.png"
import YELLOW from "../../assets/ProfileImgs/ProfileImg2.png"
import BLUE from "../../assets/ProfileImgs/ProfileImg3.png"
import GREEN from "../../assets/ProfileImgs/ProfileImg4.png"
import PrevGameCard from '../../components/Mypage/PrevGameCard';
import { PreferredStyle, preferredStyleMapping, ProfileImage, Sex, sexMapping, Skill, skillMapping } from '../../components/RankCopmos/PlayerType';
import axios from 'axios';
import { prevMatchesProps } from '../user/mypage';

interface DetailPlayerType {
    nickname: string
    profileImage: ProfileImage
    sex: Sex
    stylePreference: PreferredStyle
    skill: Skill[]
    ranking: number
    rating: number
    matchCount: number// 참여한 경기
    mannersKingCount: number
    skillKingCount: number
    socialKingCount: number
    noShowCount: number,
    badMannerCount: number,
    foulKingCount: number
    pastMatches: prevMatchesProps[]
    crewRanking: {
        crewName: string,
        rank: number,
        rating: number,
        winRate: number
    }
}


const mykickInfo = [
    {
        title: "나의 랭킹", unit: "위"
    },
    {
        title: "나의 평점", unit: "점"
    },
    {
        title: "참여한 경기", unit: "경기"
    },]

const PlayerDetail = () => {
    const { playerId } = useParams()
    const navigate = useNavigate()

    const [playerInfo, setPlayerInfo] = useState<DetailPlayerType>()

    const imgRevert = (img: ProfileImage) => {
        if (img === ProfileImage.RED) {
            return RED
        } else if (img === ProfileImage.YELLOW) {
            return YELLOW
        } else if (img === ProfileImage.BLUE) {
            return BLUE
        } else if (img === ProfileImage.GREEN) {
            return GREEN
        } else return RED
    }


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/members/info/${playerId}`).then(res => {
            setPlayerInfo(res.data.data)
        }).catch(err => console.log(err))
    }, [])

    return (
        <div>
            <div className='py-5'>
                <IoIosArrowBack onClick={() => navigate(-1)} size={24} color="#A1A1A1" />
            </div>
            <div className='pt-6 pb-16 flex item-center space-x-[22px]'>
                <img src={imgRevert(playerInfo?.profileImage || ProfileImage.RED)} className='w-[120px] h-[120px] rounded-3xl bg-cardBgColor' />
                <div className='text-opacity-70 '>
                    <div className='pt-[10px] pb-2 space-x-2'>
                        <span className='text-opacity-100 font-Pretendard_Bold text-2xl'>{playerInfo?.nickname}</span>
                        <span >{playerInfo ? sexMapping[playerInfo.sex] : ""}</span>
                    </div>
                    <div className='bg-[#212121] rounded-lg opacity-70  flex justify-center px-2 py-1 text-sm'>
                        크루 | {playerInfo?.crewRanking?.crewName ? playerInfo.crewRanking.crewName : "소속 크루 없음"}
                    </div>
                    <div className='pt-4 pb-[10px] space-x-1'>
                        <span className='opacity-70 pr-2'>{playerInfo ? preferredStyleMapping[playerInfo?.stylePreference] : ""}키커</span>

                        {playerInfo?.skill?.map((item, index) => <span key={index} className='text-[10px] opacity-50'>#{skillMapping[item]}</span>)}


                    </div>
                </div>
            </div>
            <div className='font-Pretendard_Bold text-sm '>
                <div className='space-y-4 pb-8'>
                    <p className='font-Montserrat font-bold text-pointColor'>MY KICK</p>
                    <div className='space-y-2'>
                        {mykickInfo.map((item, index) =>
                            <div key={index} className='cardWrap flex justify-between px-4 py-6 '>
                                <p>{item.title}</p>
                                <p className={index === 0 ? "text-pointColor" : ""}>{index === 0 ? playerInfo?.ranking : index === 1 ?
                                    playerInfo?.rating : playerInfo?.matchCount
                                }{item.unit}</p>
                            </div>)}
                    </div>
                </div>
                <div className='space-y-4 pb-8'>
                    <p>추천 항목</p>
                    <div className='grid grid-cols-3 gap-2 text-'>
                        {["매너왕", "실력왕", "분위기 메이커"].map((item, index) =>
                            <div className='cardWrap space-y-[44px] py-3 px-3'>
                                <div>{item}</div>
                                <div>{index === 0 ? playerInfo?.mannersKingCount : index === 1 ? playerInfo?.skillKingCount : playerInfo?.socialKingCount}경기</div>
                            </div>)}
                    </div>
                </div>
            </div>
            <div className='space-y-4 pb-4'>
                <p>패널티 항목</p>
                <div className='space-y-2'>
                    {["거친 플레이어", "좋지 않은 매너"].map((item, index) =>
                        <div className='cardWrap flex justify-between items-center px-4 py-6 '>
                            <div>{item}</div>
                            <div className='text-warningColor'>{index === 0 ? playerInfo?.foulKingCount : playerInfo?.badMannerCount}경기</div>
                        </div>
                    )}
                </div>
            </div>
            <div className='py-4 space-y-4'>
                <div className='flex justify-between'>
                    <p>지난 경기를 확인해 보세요</p>
                    <IoIosArrowForward onClick={() => navigate(`/player/detail/${playerId}/prevMatches`)} size={24} color="#A1A1A1" />
                </div>
                <div className="flex items-center gap-3 overflow-y-scroll">
                    <div className="flex gap-2">
                        {playerInfo?.pastMatches ?
                            playerInfo?.pastMatches.map((item, index) =>
                                <PrevGameCard key={index} {...item} />
                            )
                            : "이전 매치가 없습니다!"}

                    </div>
                </div>


            </div>

        </div>
    );
};

export default PlayerDetail;