import { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";

interface InputCheckBoxSelectorProps {
  selectedItems: (string | number)[];
  onItemChange: (items: (string | number)[]) => void;
  title: string;
  itemList: string[];
  placeholder: string;
  modalWidth?: string;
  modalPadding?: string;
  modalMargin?: string;
  modalHeight?: string;
}

const InputCheckBoxSelect = ({
  selectedItems,
  onItemChange,
  title,
  itemList,
  placeholder,
  modalWidth = "100%",
  modalPadding = "16px",
  modalMargin = "28px",
  modalHeight = "271px",
}: InputCheckBoxSelectorProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    setTimeout(() => setIsAnimating(true), 10);
  };

  const closeModal = () => {
    setIsAnimating(false);
    setTimeout(() => setIsModalOpen(false), 300);
  };

  const handleItemChange = (item: string | number) => {
    const isSelected = selectedItems.includes(item);
    if (isSelected) {
      onItemChange(selectedItems.filter((i) => i !== item));
    } else {
      onItemChange([...selectedItems, item]);
    }
  };

  return (
    <div className="w-full">
      {" "}
      {/* 부모 컨테이너의 width를 100%로 설정 */}
      <button
        onClick={openModal}
        className={`w-full h-[48px] px-[16px] font-Pretendard_Regular text-neutral-500 bg-cardBgColor text-[16px] rounded-xl text-left flex items-center justify-between ${
          !selectedItems.length && "opacity-40"
        }`}
      >
        {selectedItems.length ? selectedItems.join(", ") : placeholder}
        <AiOutlineDown className="text-gray-400" />
      </button>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-end justify-center bg-black bg-opacity-50 z-50">
          <div
            className={`bg-cardBgColor rounded-t-xl relative transition-transform duration-300 ${
              isAnimating ? "translate-y-0" : "translate-y-full"
            }`}
            style={{
              width: modalWidth,
              padding: modalPadding,
              height: modalHeight,
              maxHeight: modalHeight,
            }}
          >
            <div className="text-[20px] font-bold mt-[12px] h-[48px] font-Pretendard_Bold">
              {title}
            </div>
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
            >
              <IoMdClose size={24} className="mt-[12px]" />
            </button>
            <div
              className="overflow-y-auto"
              style={{ maxHeight: `calc(${modalHeight} - 90px)` }}
            >
              <ul className="space-y-[20px] font-Pretendard_Regular">
                {itemList.map((item, index) => (
                  <li
                    key={index}
                    className="flex items-center p-2 hover:cursor-pointer rounded"
                    onClick={() => handleItemChange(item)}
                  >
                    <input
                      type="checkbox"
                      name="item"
                      value={item}
                      checked={selectedItems.includes(item)}
                      onChange={() => handleItemChange(item)}
                      className="mr-2 form-checkbox h-4 w-4 accent-pointColor"
                    />
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputCheckBoxSelect;
