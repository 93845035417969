import { FaChevronDown } from "react-icons/fa";

export default function MatchRules() {
  return (
    <div className="w-full px-8 flex flex-col">
      <div className="flex flex-col w-full gap-10">
        <div>
          <div className="my-2 text-[22px]">🤼 매칭 안내</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              경기 시작 2시간 전까지 최소 10명부터 최대 14명까지 인원을
              모집합니다. 모집된 인원에 따라 모집이 조기 마감될 수 있습니다.
            </li>
            <li>
              경기는 [10명 : 5 vs 5 경기] 와 [12명 : 6 vs 6 경기] 로 진행합니다.
            </li>
            <li>
              홀수로 모집되는 인원의 숫자에 따라 교체선수로 배정될 수 있습니다.
            </li>
            <li>
              경기 시작 1시간 전까지 최소 인원(10명)이 모집되지 않으면 경기가
              취소됩니다.
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">👥 교체 선수 배정 기준</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              교체 선수는 매칭 신청 순서를 기준으로 교체 선수를 배정합니다.
            </li>
            <li>
              14명이 모집된 경우, 먼저 신청한 12명을 무작위로 팀에 배정하고,
              늦게 신청한 2명을 각 팀의 교체 선수로 지정합니다.
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">📊 팀 배정</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              팀 배정 시 각 팀의 개인 평점을 고려하였습니다. 즐거운 경기 되시길
              바랍니다!
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">🧑‍🤝‍🧑 크루 우선 배정 기준</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              크루 리더가 전송한 경기 초대 링크로 신청한 경우, 크루원들을
              우선적으로 한 팀에 배정합니다.
            </li>
            <li>
              인원 모집 상황에 따라 일부 크루원이 다른 팀에 배정될 수 있습니다.
            </li>

            <li>
              크루 단위로 매칭 시 인원수가 맞지 않을 경우, 매너점수가 낮은
              크루부터 다른 팀으로 나뉠 가능성이 높습니다.
            </li>
            <li>
              공정한 매칭을 위한 조치이니 모두 매너있는 경기로 즐겨주세요!
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">⌛ 취소 시 페널티</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              경기 시작 시간으로부터 2시간 이하로 남았을 때부터 매칭을 취소하는
              경우, 페널티 점수로 ‘-10점’이 부여됩니다.
            </li>
          </ol>
        </div>

        <div>
          <div className="my-2 text-[22px]">💬 게임 후 매칭 평가</div>
          <ol className="opacity-50 list-disc pl-5">
            <li>
              게임이 끝난 후, 진행된 매칭에 대한 평가는 필수로 진행해야 합니다.
            </li>
            <li>
              향후 더 나은 매칭이 이루어질 수 있도록 반영될 예정이니 활발한
              평가를 부탁드립니다.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
}
