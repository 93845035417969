import React from "react";
import InputText from "../Input/InputText";
import InputRadio from "../Input/InputRadio";
import InputActiveCheckBox from "../Input/InputActiveCheckBox";
import { FieldErrors, UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormValues } from "../Input/inputRegisterType";
import { FormType } from "./formType";
import InputRadioImg from "../Input/InputRadioImg";

export interface InfoInputWrapType {
  number: number;
  question: string;
  validation?: string;
  inputName: keyof FormValues;
  radios?: string[];
  type: FormType;
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FormValues>;
  errors?: FieldErrors<FormValues>
}

const InfoInputWrap: React.FC<InfoInputWrapType> = ({
  number,
  question,
  validation,
  inputName,
  radios,
  register,
  watch,
  type,
  errors
}: InfoInputWrapType) => {
  const renderViewByInputType = (type: FormType) => {
    switch (type) {
      case FormType.text:
        return (
          <InputText
            registerName={inputName}
            register={register}
            watch={watch}
            className={`bg-mainBgColor ${errors?.nickName?.message ? "border-warningColor focus:border-warningColor" : "border-pointColor focus:border-pointColor"}`}
          />
        );

      case FormType.checkBox:
        return (
          <InputActiveCheckBox
            watch={watch}
            registerName={inputName}
            options={radios || []}
            register={register}
          />
        );

      case FormType.radioImg:
        return (
          <InputRadioImg
            registerName={inputName}
            register={register}
            watch={watch}
          />
        );

      case FormType.radio:
        return (
          <InputRadio
            registerName={inputName}
            radios={radios || []}
            register={register}
            watch={watch}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <div className="">
      <div className="flex items-center space-x-2">
        <div className="rounded-full bg-pointColor w-6 h-6 flex justify-center items-center text-black  font-Pretendard_Bold text-[13px]">
          {number}
        </div>
        <div>
          {question}
          {type === FormType.checkBox ? (
            <span className="text-[#8C8C8C]"> (복수 가능)</span>
          ) : null}
        </div>
      </div>
      <div className="mt-3 mb-1">{renderViewByInputType(type)}</div>
      <div className={` text-[10px] ${errors?.nickName?.message ? "text-warningColor" : "text-pointColor"}`}>{errors?.nickName?.message ? errors?.nickName?.message : validation}</div>
    </div>
  );
};

export default InfoInputWrap;
