import React, { ReactNode } from "react";

interface ModalProps {
  element: ReactNode;
}

const MyPageModal = ({ element }: ModalProps) => {
  return (
    <div className="fixed z-50 top-0 left-0 w-screen h-screen bg-black/70 flex">
      <div className="bg-[#2B2B2B] w-full min-h-[30%] self-end rounded-t-3xl pt-3 pb-4 px-4 animate-mypageModal transition-transform">
        {element}
      </div>
    </div>
  );
};

export default MyPageModal;
