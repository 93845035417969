export enum ProfileImage {
    RED = "RED",
    YELLOW = "YELLOW",
    BLUE = "BLUE",
    GREEN = "GREEN",
}

export enum Sex {
    FEMALE = "FEMALE",
    MALE = "MALE",
}

export enum PreferredStyle {
    ATTACK = "ATTACK",
    DEFENSE = "DEFENSE",
}

export enum Skill {
    SHOT = "SHOT",
    SPEED = "SPEED",
    DRIBBLE = "DRIBBLE",
    PHYSICAL = "PHYSICAL",
    STAMINA = "STAMINA",
    GOALKEEPER = "GOALKEEPER",
}

export const sexMapping: { [key in keyof typeof Sex]: string } = {
    FEMALE: "여성",
    MALE: "남성",
};

export const preferredStyleMapping: { [key in keyof typeof PreferredStyle]: string } = {
    ATTACK: "공격",
    DEFENSE: "수비",
};

export const skillMapping: { [key in keyof typeof Skill]: string } = {
    SHOT: "슛",
    SPEED: "스피드",
    DRIBBLE: "드리블",
    PHYSICAL: "피지컬",
    STAMINA: "지구력",
    GOALKEEPER: "골키퍼",
};