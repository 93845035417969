import React from "react";
import "./inputLabel.css";
import { FormValues, InputRegisterType } from "./inputRegisterType";

import { UseFormWatch } from "react-hook-form";

interface InputActiveCheckBoxType extends InputRegisterType {
  options: string[];
  watch: UseFormWatch<FormValues>;
}

const checkBoxOptions: { [key: string]: keyof FormValues } = {
  슛: "shot",
  스피드: "speed",
  드리블: "dribble",
  골키퍼: "goalKeeper",
  피지컬: "physical",
  지구력: "stamina",
};

const InputActiveCheckBox = ({
  options,
  register,
  watch,
}: InputActiveCheckBoxType) => {
  const watchedValues = watch();
  return (
    <div className="inputLabelWrap">
      {options.map((option, index) => {
        const isChecked = watchedValues[checkBoxOptions[option]] || false;
        return (
          <label
            htmlFor={checkBoxOptions[option]}
            key={index}
            className={`inputLabel animation-click ${isChecked ? "selcted" : ""}`}
          >
            <input
              {...register(checkBoxOptions[option])}
              type="checkbox"
              id={checkBoxOptions[option]}
              className="hidden"
            />
            {option}
          </label>
        );
      })}
    </div>
  );
};

export default InputActiveCheckBox;
