import React, { useEffect, useState } from "react";
import InfoInputWrap from "../../components/SignUpCompos/InfoInputWrap";
import Button from "../../components/Button/Button";
import { SubmitHandler, useForm } from "react-hook-form";
import { FormValues } from "../../components/Input/inputRegisterType";
import { FormType } from "../../components/SignUpCompos/formType";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { PreferredStyle, Sex } from "../../components/RankCopmos/PlayerType";


const SignUp = () => {
  const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm<FormValues>();
  const [cookies] = useCookies()
  const navigate = useNavigate()


  const getTrueKeysUpperCase = (obj: FormValues): string[] => {
    return (Object.keys(obj) as Array<keyof FormValues>) // 객체의 모든 키를 배열로 반환
      .filter(key => obj[key] === true) // true인 키만 필터링
      .map(key => key.toUpperCase()); // 대문자로 변환
  };

  const onValid: SubmitHandler<FormValues> = (data) => {
    axios.get(`${process.env.REACT_APP_API_URL}/members?nickname=${data.nickName}`, {
      headers: {
        Authorization: `Bearer ${cookies.accessToken}`
      }
    }).then(res => {
      if (res.data.data.isAvailable) {

        const profileImage = data.profileImg
        const nickname = data.nickName
        const sex = data.sex === "여자" ? Sex.FEMALE : Sex.MALE
        const stylePreference = data.gameStyle === "공격" ? PreferredStyle.ATTACK : PreferredStyle.DEFENSE
        const skill = getTrueKeysUpperCase(data)


        navigate("/mailAuth", {
          state: {
            profileImage: profileImage,
            nickname: nickname,
            sex: sex,
            stylePreference: stylePreference,
            skill: skill
          }
        })

      } else {
        setError("nickName", { message: "*중복된 닉네임입니다." })
      }
    }).catch(err => console.log(err))


  };

  // 모든 입력 필드를 watch로 추적합니다.
  const profileImg = watch("profileImg");
  const nickName = watch("nickName");
  const sex = watch("sex");
  const gameStyle = watch("gameStyle");

  // 체크박스 필드들을 watch로 추적합니다.
  const shot = watch("shot");
  const speed = watch("speed");
  const dribble = watch("dribble");
  const goalKeeper = watch("goalKeeper");
  const physical = watch("physical");
  const stamina = watch("stamina");

  const [isButtonActive, setIsButtonActive] = useState(false);


  useEffect(() => {
    const areCheckBoxesValid =
      shot || speed || dribble || goalKeeper || physical || stamina;


    if (profileImg && nickName && sex && gameStyle && areCheckBoxesValid) {
      setIsButtonActive(true);
    } else {
      setIsButtonActive(false);
    }

    if (nickName)
      if (nickName.length > 20) {
        setError("nickName", { message: "*20글자 이내로 입력해주세요" })
      } else if (nickName.length >= 3 && nickName.length <= 20) {
        clearErrors("nickName")
      }


  }, [profileImg, nickName, sex, gameStyle, shot, speed, dribble, goalKeeper, physical, stamina]);



  return (
    <div className="relative px-5 pb-4">
      <div className="py-6 text-xl">정보 입력</div>
      <form onSubmit={handleSubmit(onValid)} className="space-y-7">
        <InfoInputWrap
          number={1}
          question="프로필 일러스트를 선택해주세요"
          inputName="profileImg"
          register={register}
          watch={watch}
          type={FormType.radioImg}
        />
        <InfoInputWrap
          number={2}
          question="닉네임을 입력해주세요."
          inputName="nickName"
          validation="* 최소 3글자 이상 입력해주세요"
          register={register}
          watch={watch}
          type={FormType.text}
          errors={errors}
        />

        <InfoInputWrap
          number={3}
          question="키커의 성별을 알려주세요."
          inputName="sex"
          radios={["남자", "여자"]}
          register={register}
          watch={watch}
          type={FormType.radio}
        />

        <InfoInputWrap
          number={4}
          question="선호하는 경기 스타일을 선택해주세요."
          inputName="gameStyle"
          radios={["공격", "수비"]}
          register={register}
          watch={watch}
          type={FormType.radio}
        />

        <InfoInputWrap
          number={5}
          question="자신있는 능력을 선택해 주세요."
          inputName="skill"
          radios={["슛", "스피드", "드리블", "골키퍼", "피지컬", "지구력"]}
          register={register}
          watch={watch}
          type={FormType.checkBox}
        />

        <Button
          text="KICKING 가입하기"
          className=" flex text-center justify-center items-center"
          active={isButtonActive}
        />
      </form>
    </div>
  );
};

export default SignUp;
