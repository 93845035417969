import { useRef, useEffect, useState } from "react";

interface ScrollPickerProps {
  list: (string | number)[];
  onSelectedChange?: (selected: string | number) => void;
  initialSelected?: string | number;
}

const Picker = ({
  list,
  onSelectedChange,
  initialSelected,
}: ScrollPickerProps) => {
  const SCROLL_DEBOUNCE_TIME = 100;
  const ITEM_HEIGHT = 48;
  const ref = useRef<HTMLUListElement>(null);
  const initialIndex =
    initialSelected !== undefined
      ? list.indexOf(initialSelected)
      : Math.floor((list.length - 1) / 2);
  const [selected, setSelected] = useState(initialIndex);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const timerRef = useRef<number | null>(null);

  const handleScroll = () => {
    if (ref.current) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = window.setTimeout(() => {
        const index = Math.floor(
          (ref.current!.scrollTop + ITEM_HEIGHT / 2) / ITEM_HEIGHT
        );
        if (list[index] !== undefined) {
          setSelected(index);
          ref.current!.scrollTo({
            top: index * ITEM_HEIGHT,
            behavior: "smooth",
          });
          onSelectedChange && onSelectedChange(list[index]);
        }
      }, SCROLL_DEBOUNCE_TIME);
    }
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = selected * ITEM_HEIGHT;
    }
  }, [selected]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = selected * ITEM_HEIGHT;
    }
  }, []);

  return (
    <ul
      ref={ref}
      onScroll={handleScroll}
      className="list-none m-0 p-0 overflow-hidden w-[75px] h-[150px] overflow-y-scroll relative text-[24px] scrollbar-hide"
    >
      <div className="h-[48px] sticky top-[48px]"></div>
      {list.map((item, index) => (
        <li
          key={index}
          className={`w-[75px] h-[48px] flex items-center justify-center ${
            index === selected
              ? "font-bold opacity-100 bg-cardBgColor rounded-xl"
              : "opacity-40"
          }`}
          ref={(el) => (itemRefs.current[index] = el)}
        >
          {item}
        </li>
      ))}
      <div className="h-[48px] sticky bottom-[48px]"></div>{" "}
      {/* 마지막 값 선택을 위한 추가 */}
    </ul>
  );
};

export default Picker;
