import React from 'react';
import { Link } from 'react-router-dom';

interface CardType {
    text: string,
    imgUrl: string,
    url: string
}
const Card = ({ text, imgUrl, url }: CardType) => {
    return (
        <Link to={url} className=" btn bg-cardBgColor flex px-2 pt-3 rounded-lg space-x-4 w-[100px] relative h-[88px] text-start text-white">
            <span className="text-sm ">{text}</span>
            <img src={imgUrl} alt={text} className="absolute right-2 bottom-0" />
        </Link>
    );
};

export default Card;