import { HiXMark } from "react-icons/hi2";
import share from "../../../assets/shareImage.png";
import { Dispatch, SetStateAction } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";

interface ShareButtonProps {
  setIsShare: Dispatch<SetStateAction<boolean>>;
}

export default function ShareModal({ setIsShare }: ShareButtonProps) {
  const { matchId } = useParams();
  const [cookies] = useCookies();

  const onShareClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/matches/${matchId}/invitation`
      );

      // 클립보드 복사
      if (response.data.isSuccess) {
        const matchUrl = response.data.data.matchUrl;

        // Web Share API를 사용하여 공유 창 열기
        if (navigator.share) {
          try {
            await navigator.share({
              title: "이 경기 어때요?🤼",
              text: `아래 링크를 통해 친구와 함께 위킥에 참여하세요!\n${matchUrl}\n[한국외대 재학생 풋살 매칭 WEKICK ⚽🥅 ]`,
            });
            alert("링크를 공유했습니다!");
          } catch (error) {
            alert("공유에 실패했습니다.");
          }
        } else {
          alert(
            "이 브라우저는 공유 기능을 지원하지 않습니다.\n 직접 링크를 복사해서 공유해주세요!"
          );
        }
      } else {
        alert(`초대 링크 생성 실패: ${response.data.message}`);
      }

      // 공유 모달 닫기
      setIsShare(false);
    } catch (error) {
      console.error("Error fetching match URL: ", error);
    }
  };

  return (
    <div className="fixed z-50 top-0 left-0 flex justify-center items-center px-1 py-2 bg-black bg-opacity-85 w-full h-full">
      <div className="bg-cardBgColor w-[80%] h-[60%] px-2 py-4 flex flex-col justify-between items-center rounded-2xl">
        <div
          className="flex justify-end px-2 w-full"
          onClick={() => {
            setIsShare(false);
          }}
        >
          <HiXMark className="size-5 hover:bg-pointColor hover:text-black rounded-full" />
        </div>
        <img src={share} alt="shareImage" className="w-36" />
        <div className="text-xl">크루원들에게 공유해보세요!</div>
        <div className="w-full flex flex-col gap-3 justify-center items-center">
          <div className="text-center text-[15px] opacity-50 py-4">
            크루원과 연결해 더 재미있는 <br /> 풋살경기를 만들어보세요.
          </div>
          <div className="flex items-center justify-around w-full">
            <button
              onClick={() => {
                setIsShare(false);
              }}
              className="w-[45%] bg-[#505050] text-opacity-75 rounded-lg py-3"
            >
              닫기
            </button>
            <button
              onClick={() => {
                onShareClick();
              }}
              className="w-[45%] bg-pointColor text-black rounded-lg py-3"
            >
              공유하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
