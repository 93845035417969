import { useEffect, useState } from "react";
import CalendarWrap from "../../components/CalendarCompos/CalendarWrap";
import Picker from "../../components/Input/InputRouette";
import { IoIosArrowDown } from "react-icons/io";
import MatchGameCard from "../../components/MatchGameCard";
import Button from "../../components/Button/Button";
import MyPageModal from "../../components/MyPageModal";
import { useRecoilValue } from "recoil";
import { MatchGameCardType, selectedDateState } from "../../atom";
import moment from "moment";
import axios from "axios";

export default function Match() {
  const [clickTime, setClickTime] = useState(false);
  const date = moment();

  const selectedDate = useRecoilValue(selectedDateState);
  const [matchGameList, setMatchGameList] = useState<MatchGameCardType[]>([]);
  const [matchGameListByTime, setMatchGameListByTime] = useState<MatchGameCardType[]>([]);

  const getNearestHour = (date: moment.Moment) => {
    const hours = date.hours();
    return `${hours < 10 ? "0" : ""}${hours}:00`;
  };

  const [hours, setHours] = useState<string | number>(getNearestHour(date)); // 현재 시간
  const [selectedHours, setSelectedHours] = useState<string | number>(getNearestHour(date)); // 선택될 시간

  const [timeArray, setTimeArray] = useState<string[]>([])


  const onSelectedChange = (selected: string | number) => {
    setSelectedHours(selected);
  };

  const onClickTimeSelect = () => {
    setClickTime(false);

    const newMatchList = matchGameList.filter((match) => {
      if (isPastTime(match.matchTime)) return true // 현재 시간보다 지난거 그냥 무조건
      const matchDateTime = moment(match.matchTime);

      // selectedDate에 selectedHours를 적용하여 필터링할 시간 생성
      let filterDateTime = moment(selectedDate); // selectedDate를 기준으로 filterDateTime을 설정
      if (typeof selectedHours === 'string') {
        const [hour, minutes] = selectedHours.split(":").map(Number); // selectedHours에서 시간과 분 추출
        filterDateTime.set({ hour, minute: minutes, second: 0, millisecond: 0 });
      } else if (typeof selectedHours === 'number') {
        filterDateTime.set({ hour: selectedHours, minute: 0, second: 0, millisecond: 0 });
      }

      // matchDateTime이 filterDateTime 이후인지 비교
      return matchDateTime.isSameOrAfter(filterDateTime);
    });

    setMatchGameListByTime(newMatchList);
  };

  const isPastTime = (inputTime: string) => {
    const inputDate = moment(inputTime);
    return inputDate.isSameOrBefore(date);
  };

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/matches/by-date?date=${selectedDate.clone().format("YYYY-MM-DD")}`)
      .then(res => {
        setMatchGameListByTime(res.data.data.matchList);
        setMatchGameList(res.data.data.matchList);
      }).catch(err => console.log(err));
  }, [selectedDate]);

  useEffect(() => {
    if (selectedDate.format("YYYY-MM-DD") === date.format("YYYY-MM-DD")) setSelectedHours(hours)
    else setSelectedHours("00:00");

    const hoursArray = [];
    const fisrtHours = selectedDate.format("YYYY-MM-DD") === date.format("YYYY-MM-DD") ? date.hours() : 0
    for (let i = fisrtHours; i < 24; i++) {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      hoursArray.push(hour);
    }
    setTimeArray(hoursArray)

  }, [selectedDate]);

  return (
    <div className="pageContainer">
      <CalendarWrap />
      <div className="pt-7">
        <div className="flex justify-between">
          <p className="font-Pretendard_Bold text-xl">모집중인 경기</p>
          <div>
            <div
              className="flex items-center bg-cardBgColor p-1 px-2 rounded-md text-sm cursor-pointer hover:bg-cardHoverBg"
              onClick={() => setClickTime(true)}
            >
              <p>{selectedHours} 이후</p>
              <IoIosArrowDown />
            </div>
          </div>
        </div>
        <div className="py-5 space-y-3">
          {matchGameListByTime.map((game, index) => (
            <div key={index} className={`${isPastTime(game.matchTime) ? "opacity-20" : ""}`}>
              <MatchGameCard {...game} />
            </div>
          ))}
        </div>
      </div>

      {clickTime && (
        <MyPageModal
          element={
            <div className="relative max-h-screen overflow-y-auto p-4">
              <div className="flex items-center justify-between">
                <p className="font-Pretendard_Bold text-xl py-2">시간 선택</p>
                <button
                  className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                  onClick={() => setClickTime(false)}
                >
                  ✕
                </button>
              </div>
              <div className="flex justify-center py-2">
                <Picker
                  list={timeArray}
                  initialSelected={selectedHours}
                  onSelectedChange={onSelectedChange}
                />
              </div>
              <div onClick={onClickTimeSelect}>
                <Button text="확인" active />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
}
