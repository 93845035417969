import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./layout";
import Grade from "./page/match/grade";
import Match from "./page/match/match";
import Rent from "./page/rent/rent";
import MatchDetail from "./page/match/match-detail";
import Login from "./page/auth/login";
import MyPage from "./page/user/mypage";
import NotFound from "./notFound";
import Rank from "./page/rank/rank";
import GradeCrew from "./page/match/grade-crew";
import GradeMatch from "./page/match/grade-match";
import SignUp from "./page/auth/signUp";
import ChangeUserInfo from "./page/user/ChangeUserInfo";
import GradeIndividual from "./page/match/grade-individual";
import MailAuth from "./page/auth/mailAuth";
import StartPage from "./page/startPage";
import Home from "./page/home";
import PrevMatches from "./page/user/PrevMatches";
import CreateCrew from "./page/crew/create-crew";
import CrewDetail from "./page/crew/detail-crew";
import ChangeCrewInfo from "./page/crew/changeInfo-crew";
import PlayerDetail from "./page/rank/detailPlayer";
import Redirect from "./page/auth/redirect";
import AcceptInvite from "./page/crew/acceptInvite";
import ProtectedRoute from "./components/ProtectedRoute";
import mataTagImg from "./assets/metaTagImg.png";
import { RecoilRoot } from "recoil";

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/home",
        element: <Home />,
      },
      { path: "/match", element: <Match /> },
      { path: "/rent", element: <Rent /> },
      { path: "/match/grade/:matchId", element: <Grade /> },
      {
        path: "/mypage",
        element: (
          <ProtectedRoute>
            <MyPage />
          </ProtectedRoute>
        ),
      },
      { path: "/rank", element: <Rank /> },
      { path: "/*", element: <NotFound /> },
      { path: "/crew/detail/:detailId", element: <CrewDetail /> },
      { path: "/player/detail/:playerId", element: <PlayerDetail /> },
    ],
  },
  {
    path: "/",
    element: <StartPage />,
  },
  {
    path: "/signUp",
    element: (
      <ProtectedRoute>
        <SignUp />
      </ProtectedRoute>
    ),
  },
  { path: "/login", element: <Login /> },
  {
    path: "/mailAuth",
    element: (
      <ProtectedRoute>
        <MailAuth />
      </ProtectedRoute>
    ),
  },
  { path: "/match/detail/:matchId", element: <MatchDetail /> },
  {
    path: "/grade/crew/:matchId",
    element: (
      <ProtectedRoute>
        <GradeCrew />
      </ProtectedRoute>
    ),
  },
  {
    path: "/grade/match/:matchId",
    element: (
      <ProtectedRoute>
        <GradeMatch />
      </ProtectedRoute>
    ),
  },
  {
    path: "/grade/indiv/:matchId",
    element: (
      <ProtectedRoute>
        <GradeIndividual />
      </ProtectedRoute>
    ),
  },
  { path: "/grade", element: <Grade /> },
  {
    path: "/mypage/modify",
    element: (
      <ProtectedRoute>
        <ChangeUserInfo />
      </ProtectedRoute>
    ),
  },
  { path: "/mypage/prevMatches", element: <PrevMatches /> },
  { path: "/crew/detail/:detailId/prevMatches", element: <PrevMatches /> },
  { path: "/player/detail/:playerId/prevMatches", element: <PrevMatches /> },
  {
    path: "/crew/create",
    element: (
      <ProtectedRoute>
        <CreateCrew />
      </ProtectedRoute>
    ),
  },
  {
    path: "/crew/modify/:detailId",
    element: (
      <ProtectedRoute>
        <ChangeCrewInfo />
      </ProtectedRoute>
    ),
  },
  { path: "/kakao/callback", element: <Redirect /> },
  {
    path: "/crews/:crewId/recruit/:recruitCode/accept",
    element: (
      <RecoilRoot>
        <AcceptInvite />
      </RecoilRoot>
    ),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
