import axios from "axios";
import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["accessToken", "refreshToken"]);
  const redirectUrl = localStorage.getItem("crewAcceptRedirectUrl");

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/auth/login/kakao/token?code=${code}`
      )
      .then((res) => {
        const time = 3600; //1시간
        const expiration = new Date(Date.now() + time * 1000);

        setCookie("accessToken", res.data.data.accessToken, {
          path: "/",
          expires: expiration,
        });
        setCookie("refreshToken", res.data.data.refreshToken, {
          path: "/",
          expires: expiration,
        });

        const matchId = res.data.data.unReviewedMatch.matchId;

        if (res.data.data.isFirst) {
          navigate("/signUp");
        } else {
          if (redirectUrl !== null && redirectUrl !== undefined) {
            navigate(`${redirectUrl}`);
          } else {
            if (matchId !== null) {
              navigate(`/grade/match/${matchId}`);
            } else {
              navigate("/home");
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="h-screen flex justify-center items-center">
      <span className="loading loading-spinner loading-lg text-pointColor"></span>
    </div>
  );
};

export default Redirect;
