import React from 'react';

interface AnnounceWrapType {
    title: string | JSX.Element
    content: string | JSX.Element
}
const AnnounceWrap = ({ title, content }: AnnounceWrapType) => {
    return (
        <div className='space-y-3 '>
            <div>{title}</div>
            <div className='text-sm bg-cardBgColor px-4 py-3 rounded-lg h-[88px] overflow-y-scroll opacity-60'>
                {content}
            </div>
        </div>
    );
};

export default AnnounceWrap;