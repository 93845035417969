import { useState } from "react";
import Button from "../../components/Button/Button";
import {
  TbCircleNumber1Filled,
  TbCircleNumber2Filled,
  TbCircleNumber3Filled,
} from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import Picker from "../../components/Input/InputRouette";
import InputCircleSelect from "../../components/Input/InputCircleSelect";

export default function GradeCrew() {
  const location = useLocation();
  const { state } = location; // matchId, crewList 등이 전달됨
  const [selectedCrew, setSelectedCrew] = useState<string>(""); // 사용자가 선택한 크루
  const [selectedMannerScore, setSelectedMannerScore] = useState<number | string>(1);
  const [selectedSkillScore, setSelectedSkillScore] = useState<number | string>(1);
  const [cookies] = useCookies();
  const navigate = useNavigate();

  // crewList가 undefined인 경우 기본값으로 빈 배열을 설정
  const crewList = Array.isArray(state.crewList) ? state.crewList : []; // crewList가 배열로 들어오는지 확인

  // 버튼 활성화 상태를 확인하는 조건
  const isButtonActive = selectedCrew !== "" && selectedMannerScore !== "" && selectedSkillScore !== "";

  const handleSubmit = async () => {
    if (!isButtonActive) return;

    const payload = {
      userId: state.userId,
      matchId: state.matchId,
      crewName: selectedCrew, // 사용자가 선택한 크루 이름 사용
      mannerScore: Number(selectedMannerScore),
      skillScore: Number(selectedSkillScore),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/match-results/crew`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${cookies.accessToken}`,
          }
        }
      );

      if (response.data.isSuccess) {
        alert("크루 평가가 성공적으로 제출되었습니다.");
        navigate("/home");
      } else {
        alert(`크루 평가 제출 실패: ${response.data.message}`);
      }
    } catch (error) {
      console.error("크루 평가 제출 중 오류 발생:", error);
      alert("크루 평가 제출 중 오류가 발생했습니다.");
    }
  };

  return (
    <div className="pageContainer px-5">
      <div className="pt-[36px]">
        <div className="text-[20px] font-bold text-white/60 font-Pretendard_Bold">크루 평가</div>
        <div className="mt-[45px] text-[24px] font-Pretendard_Bold">
          <div>매칭된 크루를 평가해 주세요!</div>
        </div>
        <div className="mt-[48px]">
          <div className="text-[16px]">
            <div className="flex mb-[16px] items-center">
              <TbCircleNumber1Filled className="text-[24px] mr-2 font-Montserrat" color="#DAFF47" />
              <span className="font-Pretendard_Regular">크루를 선택해 주세요.</span>
            </div>
            <InputCircleSelect
              selectedItem={selectedCrew}
              onItemChange={(item) => setSelectedCrew(item.toString())} // string으로 변환
              title="크루 선택"
              itemList={crewList.length > 0 ? crewList : ["크루 없음"]} // crewList가 배열일 경우만 전달
              placeholder="크루 선택"
            />
          </div>

          <div className="mt-[40px]">
            <div className="flex mb-[16px] items-center ">
              <TbCircleNumber2Filled className="text-[24px] mr-2 font-Montserrat" color="#DAFF47" />
              <span className="font-Pretendard_Regular">크루의 매너 점수를 입력해 주세요.</span>
            </div>
            <div className="flex items-center justify-center font-Pretendard_Bold text-[24px]">
              <Picker
                list={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                onSelectedChange={setSelectedMannerScore}
                initialSelected={8}
              />
              <div className="ml-[8px] text-[40px] text-white/40 font-Pretendard_Medium">점</div>
            </div>
          </div>

          <div className="mt-[40px] mb-[20px]">
            <div className="flex mb-[16px] items-center ">
              <TbCircleNumber3Filled className="text-[24px] mr-2 font-Montserrat" color="#DAFF47" />
              <span className="font-Pretendard_Regular">크루의 실력 점수를 입력해 주세요.</span>
            </div>
            <div className="flex items-center justify-center font-Pretendard_Bold text-[24px]">
              <Picker
                list={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                onSelectedChange={setSelectedSkillScore}
                initialSelected={6}
              />
              <div className="ml-[8px] text-[40px] text-white/40 font-Pretendard_Medium">점</div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full fixed pr-10 bottom-0 pb-5 bg-backgroundColor">
        <Button text="다음" active={isButtonActive} onClick={handleSubmit} />
      </div>
    </div>
  );
}
