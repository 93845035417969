import PlayerCard from "./PlayerCard";
import TopRankedCard from "./TopRankedCard";
import { FiPlus } from "react-icons/fi";
import { Fragment } from "react/jsx-runtime";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ProfileImage } from "./PlayerType";
import { useRecoilState, useRecoilValue } from "recoil";
import { rankPageState, rankTypeState } from "../../atom";



export interface RankingPlayerDataType {
  id: number;
  mannerKingCount: number;
  matchCount: number;
  nickName: string;
  rating: number;
  skillKingCount: number;
  socialKingCount: number;
  ranking: number;
  profileImage: ProfileImage
}

export interface RankingCrewDataType {
  id: number;
  matchCount: number;
  name: string;
  rating: number;
  winRate: number;
  ranking: number;
  profileImage: ProfileImage
}

type OptionMapping = {
  [key: string]: string;
};

const PlayerTypeMapping: OptionMapping = {
  "개인 평점": "rating",
  실력: "skills",
  매너: "manner",
  "분위기 메이킹": "social",
};

const CrewTypeMapping: OptionMapping = {
  "크루 평점": "rating",
  "크루 승률": "win",
};

type RankingDataType<T> = T extends "player"
  ? RankingPlayerDataType
  : RankingCrewDataType;

const RenderRankingType = () => {
  const type = useRecoilValue(rankTypeState)
  const navigate = useNavigate();
  const koType = type === "player" ? "개인" : "크루";
  const [option, setOption] = useState("rating");
  const [rankingData, setRankingData] = useState<
    RankingDataType<typeof type>[]
  >([]);
  const [topOne, setTopOne] = useState<RankingDataType<typeof type> | null>(null);
  const [topTwo, setTopTwo] = useState<RankingDataType<typeof type> | null>(null);
  const [topThree, setTopThree] = useState<RankingDataType<typeof type> | null>(null);


  const [page, setPage] = useRecoilState(rankPageState);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false)

  const optionsType =
    type === "player"
      ? ["개인 평점", "실력", "매너", "분위기 메이킹"]
      : ["크루 평점", "크루 승률"];

  const onChangeOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    if (type === "player") {
      setOption(PlayerTypeMapping[selectedOption]);
    } else {
      setOption(CrewTypeMapping[selectedOption]);
    }
    setPage(0)
    setLoading(false)
  };

  useEffect(() => {
    const api =
      type === "player"
        ? `/member-rank/${option}?page=${page}`
        : `/crew-rank/${option}?page=${page}`;

    if (page === 0) setRankingData([])

    axios
      .get(`${process.env.REACT_APP_API_URL}${api}`)
      .then((res) => {
        const data =
          type === "player" ? res.data.data.members : res.data.data.crew;
        if (page === 0) {
          const rankedData = data.map(
            (member: RankingPlayerDataType, index: number) => ({
              ...member,
              ranking: index + 1,
            })
          );
          setRankingData(rankedData);

          const top3 = rankedData.slice(0, 3);
          setTopOne(top3[0])
          setTopTwo(top3[1])
          setTopThree(top3[2])


          setTotalPages(res.data.data.totalPages);
        } else {
          const rankedData = data.map(
            (member: RankingPlayerDataType, index: number) => ({
              ...member,
              ranking: rankingData.length + 1 + index,
            })
          );
          setRankingData((prev) => [...prev, ...rankedData]);
        }
        setLoading(true)
      })
      .catch((err) => console.log(err));
  }, [option, type, page]);

  useEffect(() => {
    setPage(0)
  }, [])

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center">
        <div className="font-Pretendard_Bold text-xl">{koType} 랭킹</div>
        <select
          onChange={onChangeOption}
          defaultValue={optionsType[0]}
          className="select select-bordered bg-cardBgColor border-none outline-none select-sm text-white"
        >
          {optionsType.map((option, index) => (
            <option key={index} value={option}>
              {option} 순
            </option>
          ))}
        </select>
      </div>
      {loading ?
        <Fragment>
          <div className="mt-4 mb-8 grid grid-cols-3 gap-3 place-items-end justify-items-center">
            {topTwo && <div
              onClick={() => navigate(`/${type}/detail/${topTwo.id}`)}
            >
              <TopRankedCard {...topTwo} />
            </div>}
            {topOne && <div
              onClick={() => navigate(`/${type}/detail/${topOne.id}`)}
            >
              <TopRankedCard {...topOne} />
            </div>}

            {topThree && <div
              onClick={() => navigate(`/${type}/detail/${topThree.id}`)}
            >
              <TopRankedCard {...topThree} />
            </div>}
          </div>
          <div className="space-y-3">
            {rankingData.slice(3).map((item, index) => (
              <div
                key={index}
                onClick={() => navigate(`/${type}/detail/${item.id}`)}
              >
                <PlayerCard {...item} />
              </div>
            ))}
            <div
              className={`text-center text-xs text-[#545454] transition-all ${page === totalPages - 1 ? "hidden" : ""
                }`}
              onClick={() => {
                setPage((prev) => (prev !== totalPages ? prev + 1 : prev));
              }}
            >
              더보기
            </div>
          </div>

          {type === "crew" ? (
            <Fragment>
              <div
                className="btn h-auto min-h-0 py-2 pl-1 pr-2 items-center fixed bottom-20 right-5 font-Pretendard_Bold text-black bg-pointColor text-sm"
                onClick={() => {
                  navigate("/crew/create");
                }}
              >
                <div className="flex justify-center items-center space-x-1">
                  <FiPlus size={20} />
                  <div>크루생성</div>
                </div>
              </div>
            </Fragment>
          ) : null}

        </Fragment>
        :
        <div className='mt-40 flex justify-center items-center'>
          <span className="loading loading-spinner loading-lg text-pointColor"></span>
        </div>
      }

    </div>
  );
};

export default RenderRankingType;
