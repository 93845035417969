import React, { useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";
import { FormValues, InputRegisterType } from "./inputRegisterType";
import "./inputLabel.css";

import ProfileImg1 from "../../assets/ProfileImgs/ProfileImg1.png";
import ProfileImg2 from "../../assets/ProfileImgs/ProfileImg2.png";
import ProfileImg3 from "../../assets/ProfileImgs/ProfileImg3.png";
import ProfileImg4 from "../../assets/ProfileImgs/ProfileImg4.png";

const profileImgs = [ProfileImg1, ProfileImg2, ProfileImg3, ProfileImg4];
const profileImg = ["RED", "YELLOW", "BLUE", "GREEN"]

const InputRadioImg = ({
  register,
  watch,
  registerName,
}: InputRegisterType) => {
  const [selcted, setSelected] = useState<number | null>(null);

  return (
    <div className="">
      <div className="flex w-full space-x-4  overflow-x-scroll overflow-y-hidden">
        {profileImgs.map((profile, index) => (
          <label
            key={index}
            className={` border ${selcted === index + 1
              ? "border-pointColor "
              : "border-cardBgColor"
              } rounded-3xl `}
            onClick={() => setSelected(index + 1)}
          >
            <input
              type="radio"
              {...register(registerName, { required: true })}
              id={`${profileImg[index]}`}
              value={`${profileImg[index]}`}
              className="hidden "
            />
            <img
              src={profile}
              className="rounded-3xl h-32 w-32 object-cover"
              style={{ maxWidth: "128px" }}
            />
          </label>
        ))}
      </div>
    </div>
  );
};

export default InputRadioImg;
